// Checkbox

.c-checkbox {
	$size: 1.7em;
	$padding: .7em;

	position: relative;
	margin-right: .5em;
	display: inline-block;
	margin: 1em 0;

	& > span {
		display: inline-block;
		vertical-align: middle;
		user-select: none;
		padding-left: $size + $padding;
		font-weight: 400 !important;
		font-size: 1rem !important;
		
		& > span {
			// box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
			content: "";
			display: inline-block;
			width: $size;
			height: $size;
			line-height: $size;
			border: 2px solid var(--secondary-green);
			margin-right: $padding;
			border-radius: 4px;
			vertical-align: middle;
			position: absolute;
			left: 0;
			top: em(-5px);
		}
	}

	input:checked + span > span {
		box-shadow: none;
	}
	input:checked + span > span::before {
		content: "";
		text-align: center;
		display: block;
		// transform: rotate(45deg) scale(.6) translateY(-3px) translateX(-1px);
		// width: 14px;
		// height: 24px;
		// border-bottom: 7px solid var(--secondary-green);
		// border-right: 7px solid var(--secondary-green);
		// margin: 0 auto;

		margin: 0 auto;
		inset: 4px;
		background: var(--secondary-green);
		position: absolute;
		border-radius: 3px;

		// box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 4px 0px rgba(255, 255, 255, 0.23) inset, 0px 0px 4px 0px rgba(0, 0, 0, 0.37) inset, 0px -1px 0px 0px rgba(0, 0, 0, 0.19) inset;
		// box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
	}

	input:focus-visible + span > span {
		outline: 2px solid #85b3fa;
		outline: 2px solid -webkit-focus-ring-color;
	}

	input {
		opacity: 0;
		position: absolute;
		width: 1em;
		height: 1em;

		&[disabled] {
			& + span > span {
				opacity: .5;
			}
		}
	}
}
