@font-face {
    font-family: 'Monument Grotesk';
    src: url('/fonts/MonumentGrotesk-Regular.woff2') format('woff2'),
        url('/fonts/MonumentGrotesk-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Monument Grotesk';
    src: url('/fonts/MonumentGrotesk-Bold.woff2') format('woff2'),
        url('/fonts/MonumentGrotesk-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



@supports (font-size: clamp(1rem,1vw,1rem)) {
  :root {
    --font-size--2:clamp(0.8rem, 0.15vw + 0.65rem, 0.9rem);
    --font-size--1:clamp(1rem, 0.22vw + 0.75rem, 1.10rem);
    --font-size-0:clamp(1.13rem, 0.23vw + 1.07rem, 1.25rem);
    --font-size-1: clamp(1.35rem, 0.39vw + 1.25rem, 1.56rem);
    --font-size-2: clamp(1.62rem, 0.61vw + 1.47rem, 1.95rem);
    --font-size-3: clamp(1.94rem, 0.9vw + 1.72rem, 2.44rem);
    --font-size-4: clamp(2.33rem, 1.31vw + 2.01rem, 3.05rem);
    --font-size-5: clamp(2.8rem, 1.85vw + 2.34rem, 3.81rem);
    --font-size-6: clamp(3.0rem, 2.3vw + 2.5rem, 4.77rem);
    --font-size-7: clamp(3.9rem, 3.5vw + 3.15rem, 5.96rem)
  }
}

@supports not (font-size: clamp(1rem,1vw,1rem)) {
  :root {
    --font-size--2:0.8rem;
    --font-size--1:1rem;
    --font-size-0:1.13rem;
    --font-size-1: 1.35rem;
    --font-size-2: 1.62rem;
    --font-size-3: 1.94rem;
    --font-size-4: 2.33rem;
    --font-size-5: 2.8rem;
    --font-size-6: 3.36rem;
    --font-size-7: 4.03rem
  }

  @media screen and (min-width: 1280px) {
    :root {
      --font-size--2:0.9rem;
      --font-size--1:1.1rem;
      --font-size-0:1.25rem;
      --font-size-1: 1.56rem;
      --font-size-2: 1.95rem;
      --font-size-3: 2.44rem;
      --font-size-4: 3.05rem;
      --font-size-5: 3.81rem;
      --font-size-6: 4.77rem;
      --font-size-7: 5.96rem
    }
  }
}


body {
	font-family: var(--font-regular);
	color: var(--theme);
	-webkit-font-smoothing: antialiased;
}

h1 {
	text-transform: uppercase;
	// font-family: Monument Grotesk;
	// font-size: 5.9375em;
	font-size: var(--font-size-6);
	@media (min-width: $small-max) {
		font-size: var(--font-size-7);
	}
	
	text-wrap: balance;
	font-style: normal;
	font-weight: 700;
	line-height: 110%; /* 6.53125rem */
	letter-spacing: 0.075rem;
	text-transform: uppercase;

	&.main-title {
		
		margin-top: 0;
		margin-bottom: 0;

		@media (min-width: $small-max) {
			margin-top: revert;
			margin-bottom: 0.5em;

			&.center {
				text-align: center;
			}
		}

		& > p {
			margin-top: 0;
			opacity: 0.5;
			font-weight: normal;
		}
	}
}

h2 {
	font-weight: 400;
	font-size: var(--font-size-5);
	line-height: 1;
	margin: 0 0 0.5em 0;
}

h3 {
	font-weight: 400;
	font-size: var(--font-size-4);
	line-height: 1;
	margin: 0 0 0.5em 0;
}

h4 {
	font-weight: 400;
	font-size: var(--font-size-3);
	line-height: 1;
	margin: 0 0 0.5em 0;
}

p {
	font-size: var(--font-size-0);
	line-height: 1.6;
	margin: 1.5rem 0;

	@media (min-width: $medium) {
		// font-size: var(--font-size-1);
	}
}

a {
	color: var(--theme);

	&:link {
		text-decoration: none;
	}

	&:visited {
		text-decoration: none;
	}

	&:hover {
		text-decoration: underline;
	}

	&:active {
		text-decoration: none;
	}
}
