.c-manual {

	margin-bottom: 2em;

	& > div {
		display: flex;
		flex-direction: column-reverse;
		align-items: flex-start;

		.wp-nav, .wp-page {
			flex: 1 1 100%;
		}

		@media (min-width: $medium) {
			flex-direction: row;
	
			.wp-nav {
				flex: 0 0 30%;
			}
			.wp-page {
				flex: 1 1 70%;
			}
		}

	}

	.wp-page {

		h1.article-title {
			color: var(--accentcolor);
			// font-size: 3rem;
			font-size:var(--font-size-4);
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.0625rem;
			text-transform: uppercase;
			margin-top:0;
		}

		border-radius: 1.5rem;
		background: white;
		color: var(--primary-dark);

		padding: var(--container-padding);
		margin: calc(-1 * var(--container-padding));
		margin-top:1em;
		margin-bottom:1em;

		a {
			word-break: break-word;
		}

		@media (min-width: $large) {
			margin:0;
			padding: 4rem 7.5rem;
		}

		@media (min-width: $xlarge) {		
	
			.weekly & {
				padding: 4rem 8.5em;
			}
		}

		@media (min-width: $xxlarge) {		
	
			.weekly & {
				// padding: 4rem 11em;
			}
		}
	}

	.wp-nav ul {
		margin: 0;
		padding: 0;
		margin-bottom: 3em;
		margin-right:4rem;
		list-style: none;


		.issue-number {
			display: block;
			margin-top: 1rem;
		}
		.issue-number + a {
			padding-top:0;
		}

		a {
			display: block;
			color: var(--theme);
			
			font-size: var(--font-size-1);
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			padding: 0.66em 0;
			transition: all 0.3s ease;
			background-color: transparent;

			opacity:0.5;

			&:hover, &.active {
				opacity: 1;
			}

			&.active {
				color:var(--accentcolor);
			}
		}

		.weekly & a {
			font-size: var(--font-size-2);
		}

	}

	&.weekly {
		.c-yearchunk {
			margin-bottom: 2em;
			
			.toggler {

				cursor: pointer;
				margin-bottom: 2em;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;

				h3 {
					margin: 0;
				}

				&:hover {
					text-decoration: underline;
				}

				img {
					display: block;
					margin: 0em 1em;
					transform: rotate(0deg);
					
					@media (min-width: $medium) {
						margin-right: 3em;
						
					}
				}				
			}

			&.active {

				.toggler img {
					transform: rotate(180deg);
				}
	
				ul {
					display: block;
				}
			}
			&:not(.active) ul{
				display: none;
			}
		}
	}
}
