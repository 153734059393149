// Body

html {
	height: 100%;
}

body {
	background: var(--background) no-repeat top center;
	background-size: 100% auto;
	height: 100%;

	@media (min-width: $medium) {
		// background-image: url('/images/body-bg-top-large.png');
		background-size: em(1920px) auto;
	}

	& > div {
		height: 100%;
	}
}

// Container

.container {
	// position: relative;

	@include container(var(--container-wide), true);
	// padding: 0;
	// padding: 2em 4.75em ;

	padding-bottom: 1px;
	@media (min-width: $large) {
		// padding: 2em 4.75em;
	}

	.container {
		padding-left: 0;
		padding-right: 0;
	}
}

.split-container {
	display: flex;
	flex-direction: column;

	@media (min-width: $large) {
		flex-direction: row;
	}

}

.app-container {
	// padding: 1em 2em;
	margin: 0;
	max-width: none;
	background-color: var(--background);
	background-image: linear-gradient(343deg, #0000002e, #00000000);
	min-height: 100%;
	color: var(--theme);
	transition: all 0.1s ease;
	padding-bottom:4em;

	@media (min-width: $large) {
		// padding: 2em 4.75em;
	}

	// display: flex;
	// justify-content: center;
	// align-items: center;
	// align-content: center;
	// &.onboarding {

	// }
}

.login-page {
	padding: 1px;
}

.login-container {
	text-align: center;
	

	.login-btn-wrapper {
		margin: 4em;

		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		align-items: center;

		iframe {
			transform: scale(1.5);
		}
	}

	.login-failed-wrapper {
		//...
		max-width: 40em;
  		margin: 7em auto;
	}
}

.intro-container {
	text-align: center;


	.video-wrapper {
		margin: 0 4em;
		// border-radius: 2.5em;
		border-radius: var(--font-size-2);
		overflow: hidden;
		box-shadow: 1em 1em 1em rgba(0, 0, 0, 0.2);
		border: 1px solid #000;
	}
	video {
		width: calc(100% + 2.2em);
		max-width: 320px;
		margin: -0.4em -1.1em;
		margin-bottom: -0.5em;
	}

	li span {
		margin-right: 0.33em;
		display: inline-block;
	}

	li strong {
		font-weight: normal;
	}

	li.done strong {
		text-decoration: line-through;		
	
	}
	li.done {
		opacity: 0.5;
	}

	li {
		line-height: 1.1;
	}

	li label {
		margin-top: 1em;
	}
	.debug-info {
		font-size: 0.66em;
	}

	ol, ul {
		margin-left:1em;
	}

	.intro-btn-wrapper {
		margin: 4em;

		display: flex;
		flex-direction: row wrap;
		align-content: center;
		justify-content: center;
		align-items: center;

		@media screen and (max-width: $small-max) {
			margin: 1.5em;
			flex-direction: column-reverse;			

			.video-wrapper {
				margin: 0;				
			}
		}

		iframe {
			transform: scale(1.5);
		}
	}

}

.app-container.intro {
	--background:var(--primary-lilac);

	h1 {
		font-size: var(--font-size-4);
		line-height: 1.3;
		color:var(--primary-rosa);
		
		span {
			line-height: 0.9;
			display: block;
			font-size: var(--font-size-6);
			color:var(--primary-white);
		}
	}
	h4 {

	}

	ol {
		margin-top:1.5em;
		max-width: 18em;
		text-align: left;
		font-size: var(--font-size-1);
		line-height: 1.2;

		li {
			margin-bottom: 1.66em;
		}
	}
}

// .login-container {
// 	min-height: 100%;
// 	min-height: 100vh;
// 	display: flex;
// 	flex-direction: column;
// 	justify-content: center;

// 	.loginForm {
// 		padding-bottom: 3em;
// 	}
// }

// Breakpoints
meta.breakpoints-small-max {
	@include variable($small-max);
}

meta.breakpoints-medium {
	@include variable($medium);
}

meta.breakpoints-medium-max {
	@include variable($medium-max);
}

meta.breakpoints-large {
	@include variable($large);
}

meta.breakpoints-large-max {
	@include variable($large-max);
}

meta.breakpoints-xlarge {
	@include variable($xlarge);
}

// Focus

html.focus-visible {
	:focus:not(.focus-visible):not(input):not(textarea):not(select) {
		outline: 0;
	}
}

// Screen reader only

.sr-only {
	clip: rect(0 0 0 0);
	clip-path: inset(100%);
	height: 1px;
	width: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
}
