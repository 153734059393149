.Toastify {
	position: absolute;
}

.Toastify__toast {
	box-shadow: 0 1em 1em 0 rgba(0,0,0,0.1), 0 2px 2em 0 rgba(0,0,0,0.05%);
	//0px 18px 1em 0px rgb(0 0 0 / 10%), 1px 5px 4em 0px rgb(0 0 0 / 5%)
	border-radius: 6px;
	padding: 1em;
}

.Toastify__toast--success {
	// background: #0091ff;
	// background: linear-gradient(317deg, #0d1fad,#000f7e);
	background: linear-gradient(317deg, var(--accentcolor), var(--accentcolor));
}
.Toastify__toast--default {
	color: var(--primary-dark);
}

.Toastify__progress-bar--default {
	background:linear-gradient(90deg, var(--accentcolor), transparent) !important;
}
