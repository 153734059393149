.app-container.podlings:not(.admin) {
	--background: var(--secondary-lgreen);
	// background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), #1E4AC6;

	& > div > div.container {
		// max-width: 60em;
	}
}
.app-container.pod:not(.admin) {

	& > div > div.container {
		max-width: 60em;
	}
}


.c-pods {

	display: flex;
  	
	flex-direction: row;
	flex-wrap: wrap;

	
	li {

		flex: 0 1 25em;
		list-style: none;
		padding: 1.5em 0em;
		// border-bottom: 1px solid rgba(255, 255, 255, 0.10);
	}
	
	ul {
		// max-width: 60em;
		width: 100%;
		margin: 0 auto;

		// display: grid;
		// grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
		// padding-left: 1.5em;

		display: flex;
		align-items: flex-start;
		align-content: flex-start;
		// gap: 80px 48px;
		gap: 3em;
		flex-wrap: wrap;

		margin-bottom: 4rem;
	}

}

.c-pods-item {

	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: flex-start;

	@media (min-width: $medium) {
		align-items: center;
	}

	.pod-details {
		// margin-right: var(--font-size-0);
		padding: 1em;
		max-width: 85%;
		margin: 0 auto;
		text-align: center;

		.name {
			font-size:var(--font-size-2);
			line-height: 1;
			font-weight:bold;
		}
		
		
		.title, .phone, .email {
			font-size:var(--font-size--1);
			opacity: 0.5;			
		}

		.email {
			overflow-wrap: break-word;
  			word-break: break-word;
		}

		& p:first-child, & p:last-child {
			// margin: 0em;
		}

		p.title {
			margin-bottom: 1rem;
		}

		p {
			margin: 0;
			font-size:var(--font-size-0);

		}
	}

	.pod-img {
		width: 20em;
  		position: relative;
		aspect-ratio: 1/1;
		border-radius: var(--radius-1);
		overflow: hidden;	
		
		img {
			width: 100%;
			object-fit: contain;			
			transition: all 0.2s ease;
		}
	}


	&:hover {
		text-decoration: none;

		.title, .phone, .email {
			opacity: 1;
		}

		.user-img img {
			transform: scale(1.05);
		}
	}

}

.c-pod {

	margin-top: var(--font-size-3);
	text-align: left;

	.row {		
	  	display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-content: flex-start;
		gap: 3em;
	}

	// .image {
	// 	img {
	// 		position: relative;
	// 		z-index: 1;
	// 	}
	// }

	.text {
		flex: 1 1 60%;

		// padding: 3em;

		& > div {
			// margin-bottom: 4em;
		}
		
	}

	.last {
		text-transform: uppercase;
		font-weight: 700;
	}

	h2 {
		// font-size: em(31px);
		font-weight: bold;
		// font-size: var(--font-size-3);
		// margin-bottom: 0;

		// span {
		// 	text-transform: uppercase;
		// 	font-weight: 700;
		// }
	}

	h2 + p {
		// margin-top: 0;
	}

	p {
		@media (min-width: $medium) {
			font-size: var(--font-size-0);
		}

		&.italic {
			font-style: italic;
		}
	}

	.image {
		position: relative;

		.slack {
			position: absolute;
			bottom: 0em;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
			align-content: center;
			width: 100%;

			img:not(.joinslack) {
				max-width: 82px !important;
				width: 82px !important;
				margin: unset !important;
				box-shadow: 0px 4px 18px -4px rgba(0, 0, 0, 0.61);
			}

			.joinslack {
				position: absolute;
				left: calc(50% + 41px - 16px);
				top: 2em;

				width: 10em;
				display: none;
	
				@media (min-width: $medium) {
					display: block;
				}
			}
		}

		svg {
			height: auto;

			path {
				fill: var(--light-gray);
			}
		}

		svg,
		img {
			border-radius: var(--radius-1);
			display: block;
			margin: auto;
			margin-bottom: 2em;
			width: em(320px);
			max-width: 100%;

			@media (min-width: $medium) {
				width: em(480px);
				margin-right: 0;
			}
		}
	}

	.buttons {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		margin-top: 3em;

		@media (min-width: $medium) {
			justify-content: flex-start;
			flex-direction: column;
		}

		button,
		a {
			background: none;
			border: none;
			padding: 0;
			font-size: var(--font-size-0);
			font-weight: 700;
			color: var(--theme);
			margin: 0 0.5em;
			// margin-bottom: 1em;
			line-height: 1.3;
			text-align: center;

			.icon {
				content: '';
				display: block;
				width: rem(62px);
				height: rem(62px);
				margin: 0 auto;
				margin-bottom: 1em;
				border-radius: 50%;
				vertical-align: middle;
				background: rgba(0, 0, 0, 0.1) no-repeat center center;

				@media (min-width: $medium) {
					display: inline-block;
					margin-right: 1.2em;
					margin-bottom: 0em;
				}
			}

			&.email {
				.icon {
					background-image: url("/images/email.svg");
				}
			}

			&.phone {
				.icon {
					background-image: url("/images/phone.svg");
				}
			}
		}
	}

	// // Profile
	&.profile {

		.buttons {
			display: none;
		}
	}

	.description {
		color:var(--primary-dark);
	}

	.c-members {
		--theme: var(--primary-dark);
		color: var(--theme);
		// padding: 0em 3em;
		margin-top: 3em;
		padding-left: 1.5em;
		
		.c-members-list {
			list-style: none;

			li {
				font-size: var(--font-size-0);
				padding: 0.4em 0em;
			}
		}
	}
}
