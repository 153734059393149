@media print {
	@page {
 		margin: 2cm 1.5cm;
	}

	.container {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	body {
		font-size: 75%;
		color: #000;
	}

	h1, h2, h3, h4, p {
		color: #000;
	}

	.c-header, .c-footer {
		display: none;
	}
}
