// Button

.c-button {
	@include button();

	transition: all 0.33s cubic-bezier(0.23, 1, 0.32, 1);
	&:hover {
		opacity: 0.8;
		// transform: translateX(0.05em) translateY(-0.15em);
		box-shadow: -0em 0.4em 1em rgba(0, 0, 0, 0.1);
	}

	&[disabled] {
		opacity: 0.4;
		filter: grayscale(1);
	}
}
