.app-container.contacts:not(.admin) {
	--background: #1E4AC6;
	background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), #1E4AC6;

	& > div > div.container {
		// max-width: 60em;
	}
}
.app-container.profile:not(.admin) {

	& > div > div.container {
		max-width: 60em;
	}
}