.c-iconmenu {

	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-content: flex-start;
	align-items: flex-start;
	justify-content: center;

	@media (min-width: $large) {			
		justify-content: flex-start;
	}



	li {
		// flex: 1 0 auto;
		flex: 0 0 calc(33%);
		list-style: none;
		// margin-bottom: 1em;
		// background-color: yellow;
		// display: flex;

		&:nth-child(2n) {
			margin-bottom: 0.8em;
			@media (min-width: $medium) {		
				margin-bottom: 1em;
			}
		} 

		
		@media (min-width: $small) {			
			padding: 0 var(--font-size--1);
			flex: 1 1 calc(25%);
		}
		
		
		@media (min-width: $medium) {			
			padding: 0 var(--font-size--1);
			flex: 1 1 calc(33%);
		}
		
		@media (min-width: $xlarge) {			
			flex: 0 1 calc(50%);
		}
		
		@media (min-width: $xxxlarge) {			
			flex: 0 1 calc(33%);
		}

		a, img {
			// display: flex;
			// flex: 1 1 auto;
		}

		img {
			transition: all 0.3s ease;

			width: calc(var(--font-size-7)*1.25);

			aspect-ratio: 1/1;

			filter: drop-shadow(0px 0.75em 0.5em rgba(0, 0, 0, 0.33));
			
			&:hover {
				filter: drop-shadow(0px 1.5em 1.5em rgba(0, 0, 0, 0.7));
			}
		}

		a, button {
			// padding: 1em;
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		button {
			appearance: none;
			background:transparent;
			border: none;
			max-width: none;
  			width: 100%;
		}

		span {
			padding: 0.5em 0;
			color:#fff;
			font-size: clamp(1.3rem, 0.39vw + 1.15rem, 1.56rem);
			// font-weight: bold;
			text-align: center;
			z-index: 1;
			// word-break: break-all;
			// position: absolute;

			@media (min-width: $medium) {
				// font-size: var(--font-size-1);			
				padding: var(--font-size--1) 0;
			}
		}

		// span {
		// 	font-weight: bold;	
		// 	text-transform: uppercase;
		// 	position: absolute;
		// 	text-align: center;
		// 	width: 100%;
		// 	bottom: 0.5em;
		// }

		// img {
		// 	flex: 1 1 auto;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	width: 100%;
		// 	transition: all 0.3s ease-in-out;
		// 	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);

			
		// 	border-radius: var(--font-size-3);
		// }

	}
}