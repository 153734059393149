.c-search {

	.search-results {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: flex-start;
		align-content: flex-start;

		@media (max-width: $medium) {
			flex-direction: column;
		}

		li {
			list-style: none;
			border-bottom: 1px solid rgba(255, 255, 255, 0.10);
		}
		
		.c-manual {

			flex: 1 1 100%;

			p {
				opacity: 0.66;
				margin-top: 0;
				text-overflow: ellipsis;

				/* Needed to make it work */
				overflow: hidden;
				// white-space: nowrap;
				display: -webkit-box;
				-webkit-line-clamp: 3;
  				-webkit-box-orient: vertical; 				
  				word-break: break-word;
			}

			li {
				padding: 0.7em 0em;
			}

			.wp-nav ul a {
				opacity: 0.9;
			}

			
			@media (max-width: $medium) {
		
				.wp-nav ul {
					margin-right: 0;
				}
			}
			@media (min-width: $medium) {
				flex: 0 1 60%;
				li {
					padding: 1.5em 0em;
				}
			}
		}

		.c-contacts {
			flex: 1 1 100%;
			flex-direction: column;

			li {
				padding: 1.5em 0em;
			}

			@media (min-width: $medium) {
				flex: 0 1 40%;
			}
		}

		.c-podlings {
			flex: 1 1 100%;
			flex-direction: row;
			
			display: flex;
			align-items: flex-start;
			align-content: flex-start;
			gap: 3em;
			flex-wrap: wrap;

			li {
				flex: 1 1 auto;
				padding: 1.5em 0em;
			}

			@media (min-width: $medium) {
				align-items: center;
				align-content: center;
				flex: 0 1 40%;
			}

			.c-pods-item {
				flex-direction: row;
				// gap: 2em;
				display: grid;
				grid-template-columns: 1fr 2.5fr;
				align-items: start;

				.pod-img {
					// max-width:12em;
					max-width: 13.5em;
  					position: relative;
  					aspect-ratio: 1/1;
  					border-radius: var(--radius-1);  	
					  width: auto;
					
					img {
						object-fit: contain;
					}
				}
				.pod-details {
					text-align:left;
					margin:0;

					.name {
						font-size: var(--font-size-1);
					}
				}
			}
		}

	}

}
// .c-search {
// 	z-index: 1000;
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100vw;
// 	height: 100vh;
// 	display: none;
// 	flex-direction: column;
// 	justify-content: flex-start;
// 	align-items: center;
// 	padding: 1.5em 1.5em;
// 	overflow: auto;
// 	background: var(--theme) url('/images/body-bg-top.png') top center no-repeat;
// 	background-size: 100% auto;

// 	@media (min-width: $medium) {
// 		background-image: url('/images/body-bg-top-large.png');
// 		background-size: em(1920px) auto;
// 		background-position: top center;
// 	}

// 	h1 {
// 		font-size: 1em;
// 		font-weight: 700;
// 		margin-bottom: em(125px);
// 	}

// 	h3 {
// 		font-size: em(15px);
// 		font-weight: 700;
// 		margin-top: 2em;
// 		margin-bottom: 0.7em;
// 	}

// 	input[type='text'] {
// 		background: rgba(216, 216, 216, 0.3);
// 		padding: 1em 3em;
// 		border: none;
// 		border-radius: 4px;
// 		text-align: center;
// 		color: #fff;
// 		font-weight: 700;

// 		&::placeholder {
// 			opacity: 1;
// 			color: #ddd;
// 			font-weight: normal;
// 		}
// 	}

// 	.close {
// 		position: absolute;
// 		right: 1.2em;
// 		top: 0.7em;
// 		background: none;
// 		border: none;
// 		width: em(44px);
// 		height: em(44px);
// 		transform: rotate(45deg);

// 		&::before,
// 		&::after {
// 			content: '';
// 			width: em(24px);
// 			height: em(6px);
// 			background: #fff;
// 			border-radius: 1px;
// 			display: block;
// 			position: absolute;
// 			left: 50%;
// 			top: 50%;
// 			margin-left: em(-12px);
// 			margin-top: em(-3px);
// 		}

// 		&::after {
// 			transform: rotate(90deg);
// 		}
// 	}

// 	.contacts,
// 	.c-messages {
// 		width: 100%;

// 		@include container(var(--container));
// 	}

// 	.c-manual {
// 		li {
// 			margin-bottom: 0.5em;
// 		}

// 		a {
// 			display: block;
// 			color: var(--theme);
// 			font-size: em(20px);
// 			padding: 1.2em 0;
// 			padding-left: 1em;

// 			background: #fff;
// 			border-radius: 8px;
// 			padding: 1em;
// 		}
// 	}

// 	&.m-open {
// 		display: flex;
// 	}

// 	&.m-closed {
// 		display: none;
// 	}

// 	.c-contacts-item {
// 		margin-bottom: 0.5em;
// 	}
// }
