& {
	padding: 0em !important;
	color: var(--background);
	--theme:#333;
}


.inactive {
	opacity: 0.3;
}
.external {
	a {
		// color: var(--accentcolor) !important;
		&:after {
			content: ' » ';
		}
	}
}

.dashboard-heading {
	margin-bottom: 4em;
}

& {
	position: relative;
	min-height: 100vh;
	width: 100%;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	text-align: left;
	background-color: var(--gray);
}

.grid-narrow {
	max-width: none;
}

h1,
h2,
h3 {
	&:after {
		margin: 0;
	}
}

& > .left {
	// display: flex;
	// overflow: auto;
	// width: 175px;
	justify-content: space-between;
	display: flex;
	flex-direction: column;
	// flex-basis: 175px;
	flex-basis: 215px;
	flex-grow: 0;
	flex-shrink: 1;
	// max-width: 175px;
	// position: absolute;
	background-color: rgba(0, 0, 0, 0.1);
	position: relative;

	&.minimized {
		width: 64px;
		flex-basis: 64px;
		.nav {
			opacity: 0.33;
		}

		.toggle {
			bottom: 64px;
		}
	}

	.toggle {
		// position: sticky;
		bottom: 32px;
		height: 4em;
		width: 100%;
		border: none;
		background-color: rgba(127, 127, 127, 0.05);
		color: white !important;
		font-size: 1.2em;
	}

	// top: 0;
	// left: 0;
	// bottom: 0;
	.nav {
		flex: 1 1 auto;

		.logo {
			margin-top: 2em;
			max-width: 100%;
			height: auto;
		}

		h2 {
			&:after {
				background: url(../../images/admin/wave_white.svg) no-repeat left top;
			}
		}
		.navimg {
			padding: 0em;
			margin: 2em;
			max-width: 100%;
		}
		img {
			// margin: 2em;
			max-width: 100%;
			height: auto;
		}
		ul {
			margin-top: 0;
			padding-left: 0;
			list-style: none;
			padding-top: 2em;
			flex-direction: column;
			li {
				// padding-bottom: 1.6em;
				padding: 0em;
				margin-bottom: 0.5em;

				&.minor {
					a {
						opacity: 0.66;
					}
				}

				&.spacer {
					background-color: var(--very-light-fade);
					// margin: 0em;
					height: 4px;
				}

				& > ul {
					padding-top: 0;
					padding-left: 1em;
				}

				ul > li {
					a {
						padding: 0.3em 2em;
					}
				}
			}
		}
	}

	a {
		display: block;
		padding: 0.8em 2em;

		font-family: var(--font-regular);
		font-weight: 600;
		font-style: normal;
		color: white;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 0.8em;
		border-left: 4px solid rgba(0, 0, 0, 0);

		&.active {
			color: var(--accentcolor);
			border-left: 4px solid var(--accentcolor);
		}

		&:hover {
			text-decoration: underline;
		}

		&.logout {
			font-weight: 400;
			opacity: 0.7;
		}
	}

	.versionInfo {
		bottom: 0;
		left: 0;
		color: white;
		opacity: 0.5;
		font-size: 0.8em;
		padding: 0.5em 0.25em;
		text-align: center;
		background: #2b3239;
		// width: 15em;
	}
}

& > .right {
	// min-height: 100%;
	// height: 100%;
	background-color: white;
	padding: 1em 2em;

	flex-direction: column;
	flex-grow: 1;
	// flex-shrink: 1;
	display: block;
	display: flex;
	// justify-content: stretch;
	// justify-content: space-between;
	justify-content: flex-start;
	align-items: stretch;

	text-align: left;

	h1 {
		&:after {
			display: none;
		}
	}
}

h1,
h2,
h3 {
	text-align: left;
}

/*  ------------------------------ */

header,
.header-bottom {
	text-align: center;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		li {
			padding: 1em;
			// display: inline;
			a {
				color: #fff;
				font-size: 1.2em;
				text-decoration: none;

				// &.active {
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}

.header-bottom {
	position: sticky;
	top: 0;
	z-index: 1;
	margin-left: -2em;
	width: calc(100% + 4em);
}

&.unsaved {
	header {
		background-color: #939393;
	}
	nav {
		background-color: #7e7e7e;
	}
}

header {
	font-family: sofia-pro, sans-serif;
	text-align: left;
	position: relative;
	margin-top: -1em;
	margin-left: -2em;
	padding: 3em 2em;
	padding-bottom: 2em;
	width: calc(100% + 4em);

	&:before {
		content: ' ';
		background-color: rgba(31, 37, 50, 0.08);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
		pointer-events: none;
	}

	.inner {
		.top {
			.img-box {
				width: 110px;
				height: 110px;
				background-color: rgba(255, 255, 255, 0.15);
				display: inline-block;
				vertical-align: middle;
				border-radius: 4px;
				margin-top: 2em;
				margin-bottom: 2em;
				margin-right: 1em;
			}
			h1 {
				font-weight: 500;
				.category {
					text-transform: uppercase;
					letter-spacing: 0.51px;
					color: white;
					font-size: 12px;
					margin-left: 4px;
					line-height: 14px;
					display: block;
					opacity: 0.5;

					a {
						display: inline;
						color: white;
					}
				}

				a.extlink {
					text-transform: uppercase;
					letter-spacing: 0.51px;
					color: black;
					font-size: 12px;
					margin-left: 4px;
					line-height: 14px;
					margin-top: 10px;
					display: block;
					text-decoration: none;
					&:hover {
						text-decoration: underline;
					}
				}

				color: white;
				vertical-align: middle;
				display: inline-block;
				margin: 0;
				font-size: 44px;
				line-height: 44px;
				letter-spacing: -0.6px;
			}
		}
	}
}

nav {
	// font-family: sofia-pro, sans-serif;
	// background-color: var(--accentcolor);
	// padding-top: 1em;
	// padding-bottom: 1em;
	text-align: left;
	padding: 1em 2em;
	width: 100%;
	ul {
		max-width: none;
		li {
			font-size: 12px;
			font-weight: 500;
			letter-spacing: 0.51px;
			color: white;
			text-transform: uppercase;
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

header,
nav {
	background-color: var(--accentcolor);
}

&.user {
	header,
	nav {
		// background-color: var(--strong);
	}
}

/*  ------------------------------ */

.table-row {
	display: flex;
	flex-direction: row;
	width: 100%;

	.table-cell {
		flex-grow: 1;
	}
}

.common-list {
	padding: 0em !important;
}

.common-list-header {
	display: flex;
	justify-content: space-between;
	padding-bottom: 1em;
	margin-bottom: 1em;
	margin-top: 2em;
	border-bottom: 1px solid #1f2532;

	h1 {
		font-size: 43px;
		font-weight: 500;
		letter-spacing: 0;
		line-height: 52px;
		margin-top: 0;
		margin-bottom: 0;
		color: var(--accentcolor);
		line-height: 0.8;
	}

	.common-list-data {
		display: flex;
		margin-left: 2em;
		margin-right: auto;
	}

	.common-header-add {
		// margin-left: auto;
		button {
			background: none;
			border: none;
			border-radius: 0;
			box-shadow: none;
			padding: 0;
			margin: 0;
			font-size: inherit;
			font-weight: normal;
			// color: #fff;
			color: inherit;
			display: flex;
			flex-direction: row;
			align-items: center;
			text-transform: uppercase;

			img {
				margin-left: 1em;
			}
		}
	}

	.common-header-amount {
		display: flex;
		flex-direction: column;
		margin: 0em 1em;

		i {
			color: #1f2532;
			font-size: 12px;
			font-style: italic;
			letter-spacing: 0.51px;
			line-height: 14px;
			text-transform: uppercase;
			display: block;
		}
		strong {
			color: #1f2532;

			font-size: 14px;
			font-style: italic;
			font-weight: bold;
			letter-spacing: 0.6px;
			line-height: 18px;
		}
	}

	input[name="etsi"] {
		margin-bottom: 1em;
	}
}

.company-form {
	p, ul, ol, blockquote {
		font-size: 0.9em;
		color: #2b3239;
	}

	section {
		padding: 2em;
		padding-bottom: 4em;
		border-bottom: 1px dashed rgba(var(--accentcolor), 0.3);
	}
}

.ack-results {
	// background: #fafafa;
	p {
		margin-bottom: 0em;
		margin: 0em;
	}
	.bar {
		height: 1em;
		background-color: $c-accentcolor;
		min-width: 1em;
		margin-bottom: 1em;
	}
}

.page-form {
	.picture-selector {
		width: 100%;
	}
}

form {
	max-width: $xlarge;
}

.submit-holder {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	position: sticky;
	bottom: 0;
	background: rgba(255, 255, 255, 0.9);
	box-shadow: 0em -1em 1em rgba(0, 0, 0, 0.03);
	width: 100%;
	padding: 1em;

	.switcher {
		margin-top: 0;
	}

	button {
		margin: 1em;
		background-color: var(--accentcolor);
		border: none;
		outline: none;
		color: white;
		text-transform: uppercase;
		border-radius: 4em;
		padding: 1em 3em;
		cursor: pointer;
		font-family: var(--font-regular);
		font-weight: 700;
		font-style: normal;
		font-size: 1em;
		margin-bottom: 1em;
		margin-right: 1em;
	}
}

.event-product {
	display: block;
}

.product-category {
	margin: 2em 0em;

	& > div {
		max-height: 12em;
		overflow: auto;
	}

	&:after {
		content: ' ';
		position: relative;
		margin: 2em 0em;
		border-bottom: 1px solid var(--light-gray);
		width: 100%;
		display: block;
	}
}
.product-type-label {
	font-weight: bold;
	margin: 0.5em 0em;
	color: var(--accentcolor);
	text-transform: uppercase;

	& > div {
		padding-top: 0;
		margin: 0;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-direction: row;

		& > div {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 0.8em;
			color: #fff;

			span {
				margin-right: 0.5em;
				font-weight: normal;
			}
			input {
				margin: 0;
				font-size: 0.9em;
			}
		}
	}
}

.added-products {
	background-color: white;
	.product-group {
		margin-bottom: 4em;
	}
}

.available-products {
	// background-color: var(--very-light-fade);
}

.size-desc {
	font-weight: normal;
	font-size: 0.9em;
	opacity: 0.7;
}

main.admin {
	& > *:not(.header-bottom):not(header):not(.submit-holder):not(h1) {
		flex-grow: 1;
	}
}

.notesandhistory {
	// margin-top: 1em !important;
	// padding-bottom: 2em !important;

	background: var(--very-light-fade);

	.notelist,
	.revlist {
		// padding: 2em;

		overflow: auto;
		max-height: 12em;
	}
	.revlist {
		background-color: transparent;
		padding: 2em;
		max-height: 21em;
		padding-right: 0em;
	}

	.revisions {
		// opacity: 0.4;

		text-align: left;
	}

	.togglenotes {
		padding: 2em;
		background: var(--very-light-fade);
		cursor: pointer;

		img {
			margin-right: 1em;
		}
		&:hover {
			text-decoration: underline;
		}
	}
	.addnote {
		button {
			display: block;
			margin-left: auto;
		}
	}
	.note {
		font-size: 0.9em;
		margin-bottom: 1em;

		p {
			margin-bottom: 0;
		}

		.user {
			opacity: 0.5;
			font-size: 0.9em;
		}
	}
}

/* BUTTONS */

.button,
button.button,
input.button {
	background-color: var(--accentcolor);
	color: white;

	font-weight: bold;
	border: none;
	outline: none;
	border: 3px solid rgba(0, 0, 0, 0);
	border-radius: 20px;
	// padding: 1em 2em;
	padding: 0.6em 3em;
	cursor: pointer;
	max-width: 20em;
	margin: 0.2em;
	display: inline-block;
	font-size: 1em;
	font-family: $f-basic;

	transition: all 0.22s ease;
}

.button.primary,
button.primary {
	background-color: var(--accentcolor);
	color: white;
	font-weight: bold;
	font-size: 1.3em;
	border-radius: 1.3em;
}

.button.white,
button.white {
	background-color: white;
	color: var(--accentcolor);
}

.button.strong-accent,
button.strong-accent {
	background-color: $c-strongaccentcolor;
	color: white;
}

button.secondary,
.button.secondary {
	padding: 0.5em 2em;
	background-color: $secondarycolor;
	margin: 0em;
	color: $c-dark;
	font-weight: normal;
	box-shadow: none !important;
}
button.outline,
.button.outline {
	background-color: rgba(0, 0, 0, 0);
	color: white;
	border-color: white;
	box-shadow: none !important;

	&:hover {
		background-color: rgba(250, 250, 250, 0.25);
	}
}
button.outline-blue,
.button.outline-blue {
	background-color: rgba(0, 0, 0, 0);
	color: var(--primary-dark);
	border-color: var(--primary-dark);
	box-shadow: none !important;
}

button.delete,
.button.delete {
	background-color: $c-dark;
	color: white;
}

button,
.button {
	&:not(.MuiButtonBase-root) {
		box-shadow: $button-shadow;
		&:hover {
			opacity: 0.8;
			box-shadow: none;
		}
	}
}
