// .c-page {
// 	position: relative;
// 	background: #fff;
// 	border-top-left-radius: 46px;
// 	border-top-right-radius: 46px;
// 	margin: 0 calc(var(--container-padding) * -1);
// 	color: var(--theme);
// 	padding: 1.5em;
// 	min-height: calc(100vh - var(--header-height-small) - 0.5em);

// 	display: flex;
// 	flex-direction: column;

// 	@media (min-width: $medium) {
// 		padding: 2em 5em;
// 	}

	
// 	.fixer {
// 		// padding: 1.5em;
// 		// margin-top: rem(-50px);
// 		@media (min-width: $medium) {
// 			padding: 2em 5em;
// 		}

// 		& > * {
// 			// padding: 0 24px;
// 		}
// 	}
// 	.c-article + .fixer {
// 		margin-top: rem(-50px);
// 	}



	.back {
		// position: absolute;
		left: 1.5em;
		top: 2em;
		background: none;
		border: none;

		@include show($medium);

		@media (min-width: $xlarge) {
			top: 3em;
		}

		svg {
			width: em(24px);
			height: em(23px);
		}
	}

// 	&.article {
// 		padding-left: 0;
// 		padding-right: 0;
// 		padding-top: 2.5em;

// 		@media (min-width: $medium) {
// 			padding-top: 5em;
// 		}

// 		@media (min-width: $xlarge) {
// 			padding-top: 2.5em;
// 		}
// 	}

// 	&.desktop {
// 		@media (max-width: $small-max) {
// 			background: none;
// 			border-radius: 0;
// 			padding-top: 0;
// 			padding-bottom: 0;
// 			min-height: 0;
// 		}
// 	}

// 	&.contacts {
// 		@media (min-width: $medium) {
// 			margin-left: 2em;
// 		}

// 		@media (min-width: $large) {
// 			margin-left: 0;
// 		}
// 	}
// }
