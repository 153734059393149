.app-container.growth-chat:not(.admin){
	--background: var(--secondary-gold);

	.cog-menu svg path {
		fill: var(--primary-white);
	}
	.split-container {
		.content {
			ol {
				margin-left: 2em;
				li {
					margin-bottom: 3em;
				}
			}
		}
	}
}
