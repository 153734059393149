.c-contacts-item {

	display: grid;
	grid-template-columns: 3fr 1fr;
	align-items: start;

	@media (min-width: $medium) {
		align-items: center;
	}


	.user-details {
		margin-right: var(--font-size-0);

		.name {
			font-size:var(--font-size-2);
			line-height: 1;
		}
		
		
		.title, .phone, .email {
			font-size:var(--font-size--1);
			opacity: 0.5;			
		}

		.email {
			overflow-wrap: break-word;
  			word-break: break-word;
		}

		& p:first-child, & p:last-child {
			// margin: 0em;
		}

		p.title {
			margin-bottom: 1rem;
		}

		p {
			margin: 0;
			font-size:var(--font-size-0)
		}
	}

	.user-img {
		max-width: 13.5em;
  		position: relative;
		aspect-ratio: 1/1;
		border-radius: var(--radius-1);
		overflow: hidden;
		
		
		img {
			width: 100%;
			object-fit: contain;			
			transition: all 0.2s ease;
		}
	}


	&:hover {
		text-decoration: none;

		.title, .phone, .email {
			opacity: 1;
		}

		.user-img img {
			transform: scale(1.05);
		}
	}

}
