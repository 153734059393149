$breakpoint-mobile: 480px;
$breakpoint-smobile: 360px;

:global(#dqcnpsroot) {
 
}


#npsmodal__overlay {
    content: " ";
    display: block;
    position: fixed;
    z-index: 0;
    background-color: #27274F;
    opacity: 0.65;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    &.isnotvisible{
        display: none !important;
    }
}


#npsmodal {
   --nps-bg-color: #ecf9f9;
    --nps-text-color: var(--primary-lilac);
    // --nps-btn-bg-color: #f75759;
    --nps-btn-bg-color: var(--primary-orange);
    --nps-btn-color: #fff;
    --nps-loader-color: #1f2532;
    position:relative;
    z-index: 1;


    font-size: 15px;
    letter-spacing: 0;
    line-height: 25px;
    font-family: "Open Sans", sans-serif;
    // font-family: inherit;
    width: 27em;
    max-width: 100%;
    box-shadow: 0 6px 32px rgba(0, 0, 0, 0.4);

    display: flex;
    flex-direction: column;
    border-radius: var(--radius-2);
    overflow: hidden;

    // background: linear-gradient(var(--nps-bg-color), #fff);
    background-color:#fff;
   
    color: var(--nps-text-color);

    position: fixed;
    
    left:0;
    bottom:0;
    margin:2em;
    

    transform: translate(-110%, 0px);
    transition: all 0.4s ease-out;

    &__nps {

         background: linear-gradient(217deg, rgba(255,0,0,.2), rgba(255,0,0,0) 70.71%),
            linear-gradient(-53deg, rgba(0,255,0,.2), rgba(0,255,0,0) 70.71%),
            linear-gradient(336deg, rgba(0,0,255,.2), rgba(0,0,255,0) 70.71%);
    }

    @media (max-width: $breakpoint-mobile) {
        margin:1em;
        box-sizing: border-box;
        width: calc(100% - 2em);
        max-height: calc(100% - 2em);
        overflow: auto;
        transform: translate(-120%, 0px);
    }
    @media (max-width: $breakpoint-smobile) {
        margin:1em;
        width: calc(100% - 2em);
        max-height: calc(100% - 2em);
    }

    //  @media (max-width: $breakpoint-mobile) {

    //     &:before {
    //         content: " ";
    //         display: block;
    //         position: absolute;
    //         z-index: -1;
    //         background-color: #27274F;
    //         opacity: 0.65;
    //         width: 100%;
    //         height: 100%;
    //         top: 0;
    //         left: 0;
    //     }
    // }

    form {
        margin: 0;
        padding: 0;
    }

    &__button,
    &__darkbutton {
        // background-color: var(--nps-btn-bg-color);
        // color:  var(--nps-btn-color);
        // border: none;
        // display: block;
        // border-radius: 2px;
        // padding: 0.66em 3em;
        // cursor: pointer;

        // margin: 0 auto;
        // font-family: inherit;
        // font-size: 16px;
        // font-weight: bold;
        // letter-spacing: 0.01px;
        // line-height: 19px;
        // text-align: center;
        // transition: all 0.3s ease-in-out;

        // &:hover {
        //     // background-color: darken(#f75759, 5%);
        //     filter:brightness(1.05);
        //     box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
        // }

        &[disabled] {
            opacity: 0.4;
            filter:grayscale(1);
        }
    }

    &__darkbutton {        
        margin-top: 4em;
        // background-color: var(--nps-btn-color) !important;
    }

    &__close {
        background: transparent
        url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='33px' height='32px' viewBox='0 0 33 32' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Ecancel_black_24dp%3C/title%3E%3Cg id='--kysely' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='D-–-Kysely-ver-2---step-2' transform='translate(-1620.000000, -619.000000)'%3E%3Cg id='Group-2' transform='translate(1236.000000, 603.000000)'%3E%3Cg id='Group-4' transform='translate(-0.000000, 0.000000)'%3E%3Cg id='cancel_black_24dp' transform='translate(384.457831, 16.000000)'%3E%3Cpolygon id='Path' opacity='0.87' points='0 0 32 0 32 32 0 32'%3E%3C/polygon%3E%3Cpath d='M16,2.66666667 C8.62666667,2.66666667 2.66666667,8.62666667 2.66666667,16 C2.66666667,23.3733333 8.62666667,29.3333333 16,29.3333333 C23.3733333,29.3333333 29.3333333,23.3733333 29.3333333,16 C29.3333333,8.62666667 23.3733333,2.66666667 16,2.66666667 Z M16,26.6666667 C10.12,26.6666667 5.33333333,21.88 5.33333333,16 C5.33333333,10.12 10.12,5.33333333 16,5.33333333 C21.88,5.33333333 26.6666667,10.12 26.6666667,16 C26.6666667,21.88 21.88,26.6666667 16,26.6666667 Z M20.7866667,9.33333333 L16,14.12 L11.2133333,9.33333333 L9.33333333,11.2133333 L14.12,16 L9.33333333,20.7866667 L11.2133333,22.6666667 L16,17.88 L20.7866667,22.6666667 L22.6666667,20.7866667 L17.88,16 L22.6666667,11.2133333 L20.7866667,9.33333333 Z' id='Shape' fill='%2327274F' fill-rule='nonzero'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E") no-repeat;
        background-size:contain;
        width: 32px;
        height: 32px;
        margin:16px;
        margin-left: auto;
        margin-bottom: -48px;
        box-sizing: border-box;
        flex: 0 0 auto;
        padding: 0em;
        // position: sticky;
        float: right;
        top:16px;
        right:0;
        cursor: pointer;
        border:none;
        
        z-index: 2;
        transform:scale(1.00);
        transition: all 0.15s ease-in-out;
        
        &:hover {
            transform:scale(1.05);
        }

    }

    &__nps {
        display: flex;
        flex-direction: column;
        padding: 54px 32px;
        padding-bottom: 24px;
        z-index: 1;
        position: relative;
        display: block;

        @media (max-width: $breakpoint-mobile) {
            padding: 44px 24px;
            padding-bottom: 24px;
        }
    }

    &__header {
        font-size: var(--font-size-0);
        font-weight: bold;
        letter-spacing: 0;
        color:var(--primary-dark);
    }

    &__score {
        display: flex;
        flex-direction: column;

        margin:2em auto;

        p {
            margin: 0;
            margin-bottom: 0.5em;
        }

        label {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-start;

            input {
                width: 22px;
                height: 22px;
                margin: 7px;
                border: 1px solid rgba(39,39,79,0.1);
            }
        }

    }
    
    &__comment {
        margin-top: 1em;
        display: flex;
        flex-direction: column;
        flex-direction: column;
        align-items: center;
        // margin-bottom:1.5em;

        label {
            font-size: 13px;
            line-height: 17px;
            font-weight: bold;
            letter-spacing: 0.01px;
        }

        textarea {
            height: 6em;
            margin-top: 0.3em;
            border: 1px solid rgba(39,39,79,0.1);
            padding: 0.5em;
        }

        & > button {
            margin-top: 4px
        }
    }

    .emoji {
        text-align: center;
        // background-color: cyan;
        &__list {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;

            label {
                display: flex;
                flex-direction: column-reverse;
                position: relative;
                cursor: pointer;
                transition: 0.15s ease-out;
                flex: 1 1 auto;
                max-width: 20%;

                svg {
                    max-width: 100%;
                    width: 3.9rem;
                    height: 100%;
                    // height: 4rem;
                    aspect-ratio: 1/1;
                }

                &:hover {
                    transform: scale(1.1);
                }

                input:checked + svg {
                    fill: var(--nps-btn-bg-color)
                }

                input:focus-visible + svg {
                    outline: auto;
                }

                input {
                    // visibility: hidden;
                    opacity: 0;
                    position: absolute;
                    z-index: -1;
                    // width: 100%;
                    // height: 100%;
                }
            }
        }
    }

    /* ThumbsUp.css */
    .emoji-container {
        // position: fixed;
        // bottom: 0;
        // left: 50%;
        // transform: translateX(-50%);
        // display: none;
        width: 100%;
        position: fixed;
        left:0;
        height: 100%;
        overflow: hidden;
        bottom:0;
        pointer-events: none;

        .emoji-wrapper {
            position: absolute;
            bottom: 0;
            animation: floatY 3s ease-out forwards;
        }

         .emoji {
            font-size: var(--font-size-6);
            display: inline-block;
            position: absolute;
            bottom: 0;
            // animation: floatUp 3s ease-out forwards;
            // animation: floatUp 3s cubic-bezier(0.25, 0.1, 0.25, 1) forwards; /* Smooth transition */
            animation: floatX 3s infinite ease-in-out;
        }
    }

}

/* LOADER */

.loader {
	display: block;
	position: relative;
    margin:0 auto;
	width: 80px;
	height: 40px;

    position: absolute;
    left: calc(50% - 40px);
    bottom: 0.5em;

	div {
		position: absolute;
		top: 13px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background-color: var(--nps-loader-color);
		animation-timing-function: cubic-bezier(0, 1, 1, 0);

		&:nth-child(1) {
			left: 8px;
			animation: lds-ellipsis1 0.6s infinite;
		}
		&:nth-child(2) {
			left: 8px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		&:nth-child(3) {
			left: 32px;
			animation: lds-ellipsis2 0.6s infinite;
		}
		&:nth-child(4) {
			left: 56px;
			animation: lds-ellipsis3 0.6s infinite;
		}
	}
}

.isvisible {
    transform: translate(0%, 0px) !important;
    & > div {
        display: block !important;
    }
}

.isnotvisible{
   
    & > div {
        // display: none !important;
    }
}

@keyframes lds-ellipsis1 {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
@keyframes lds-ellipsis3 {
	0% {
		transform: scale(1);
	}
	100% {
		transform: scale(0);
	}
}
@keyframes lds-ellipsis2 {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(24px, 0);
	}
}

.current-mood {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em;
    margin-bottom: 1em;

    background: rgba(255, 255, 255, 0.2);
    border-radius: var(--font-size-3);
    padding: var(--font-size-1) var(--font-size-3);

    .current-mood__score {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-right: 1em;
        flex: 1 1 33%;

        svg {
            width: var(--font-size-6);
            height: var(--font-size-6);
            margin-right: 0.5em;
        }

        span {
            font-size: var(--font-size-2);
            font-weight: bold;
        }
    }

    .current-mood__description p{
        font-size: var(--font-size--1);        
    }

    &.this-week {
        transition: all 0.33s cubic-bezier(0.23, 1, 0.32, 1);
        position: relative;
        overflow: hidden;


        span {
            font-size: var(--font-size-1);
        }

        &:before {
            content: " ";
            display: block;
            position: absolute;
            background-color: #27274F;
            opacity: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
        
        &:hover {
            cursor: pointer;
            transform: scale(1.05);
            background-color: #fff;
            color: var(--primary-lilac);
            box-shadow: -0em 0.4em 1em rgba(0, 0, 0, 0.1);

            &:before {
                opacity: 1;
                background: linear-gradient(217deg, rgba(255,0,0,.2), rgba(255,0,0,0) 70.71%),
                    linear-gradient(-53deg, rgba(0,255,0,.2), rgba(0,255,0,0) 70.71%),
                    linear-gradient(336deg, rgba(0,0,255,.2), rgba(0,0,255,0) 70.71%);
            }
           


        }

    }

    &.archive {

        border-radius: var(--font-size-1);
        padding: 0 var(--font-size-2);

        svg {
            width: var(--font-size-4);
            height: var(--font-size-4);
        }

        span {
            font-size: var(--font-size-1);
            font-weight: bold;
        }
    }
}

/* Vertical (Y-axis) floating motion */
@keyframes floatY {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-33vh); /* Move up */
  }
}

/* Horizontal (X-axis) wavy motion */
@keyframes floatX {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10%);
  }
  50% {
    transform: translateX(-10%);
  }
  75% {
    transform: translateX(10%);
  }
  100% {
    transform: translateX(0);
  }
}