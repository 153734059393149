table {
	width: 100%;
	text-align: center;

	.firstMedia {
		a {
			display: block;
		}
	}

	.first_media,
	.firstMedia,
	.thumb,
	.name,
	.Nimi {
		text-align: left;
	}

	.message {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 20em;

		.message-title {
			font-weight: bold;
			margin-bottom: 0.25em;
		}
		.message-value {
			margin-top: 0em;
			font-size: 1.1em;
			overflow: hidden;
			width: 95%;
			text-overflow: ellipsis;

			.messagelist & {
				margin: 0em;
			}
		}
	}

	.revision_history {
		text-align: left;
	}

	thead {
		th {
			text-transform: uppercase;
			font-size: 0.8em;
			font-weight: 600;
			padding: 1.2em 0.4em;
		}
	}

	&.outline {
		border-top: 1px solid #333;
		border-bottom: 1px solid #333;
	}

	tbody {
		tr {
			transition: all 0.3s ease;
			height: 4em;
		}
		// tr:nth-child(odd) {
		// 	background-color: #fafafb;
		// }

		tr:hover {
			cursor: pointer;
			background-color: #eaeded;
		}

		td {
			// padding: 0.4em;
			font-size: 0.9em;
			padding: 0.6em 0.4em;
			border-bottom: 1px solid #efefef;

			img {
				margin: 0 auto;
			}
		}

		td.Nimi,
		td.name {
			font-size: 1em;
			font-weight: bold;
		}
	}

	a {
		text-decoration: none;
		color: inherit;

		&:hover {
			text-decoration: underline;
			color: inherit;
		}
	}

	.timestamp {
		opacity: 0.4;
		font-size: 0.9em;
	}

	td {
		&.id {
			width: 36px;
		}
		&.type_id {
			width: 7em;
		}
		&.firstMedia,
		&.thumb {
			// width: 74px;
			// max-width: 74px;
			max-height: 60px;
			// width: 88px;
			width: 60px;

			& > img {
				display: block;
				background-color: rgba(127, 127, 127, 0.05);
				max-width: 100%;
				max-height: 100%;
			}

			a {
				display: flex;
				justify-content: center;
				align-items: center;
				// width: 88px;
				// height: 88px;
				width: 48px;
				height: 48px;

				img {
					display: block;
					background-color: rgba(127, 127, 127, 0.05);
					max-width: 100%;
					max-height: 100%;
				}
			}

			img {
				border-radius: 3px;
				box-shadow: 0 9px 4px -6px rgba(0, 0, 0, 0.07);
			}
		}
	}

	.firstMedia,
	.thumb {
		// width: 74px;
		// // max-width: 74px;
		// max-height: 74px;
		// width: 88px;
	}

	.remove,
	.edit {
		width: 88px;
	}

	.groups {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		display: inline-block;
		max-width: 12vw;
		
		.group:not(:last-child):after {
			display: inline;
			content: ", ";
			margin-right: 0.15em;
		}
	}

	th {
		span {
			margin-right: -12px;
		}

		span.asc {
			display: inline-block;
			transform: rotate(180deg);
			transform-origin: 50% 45%;
		}

		span.sorted,
		span.unsorted {
			img {
				display: inline;
			}
		}

		span.unsorted {
			opacity: 0;
		}

		span.sorted {
			opacity: 1;
		}
	}


	.type,
	.site {
		text-transform: uppercase;
	}
}

.pagination {
	display: flex;
	justify-content: space-between;
	flex-direction: row-reverse;
	align-items: center;
	padding: 1em;

	background-color: var(--light-gray);
	// background: linear-gradient(180deg, $c-lightgray, #00000000 50%);
	background: linear-gradient(351deg, #fafafb, #00000000 100%);

	button {
		padding: 0em 2em;
		font-size: 1.4em;
		// padding-bottom: 0.6em;
	}
}

// .admin {
table {
	text-align: left;

	tbody tr:nth-child(odd) {
		background-color: #fafafb;
	}
}

.product-group {
	thead {
		tr {
			background: none;
		}
		th {
			border-bottom: 2px solid var(--light-gray);
			padding: 0.4em;
		}
	}
}
thead {
	tr {
		background: none;

		th {
			&.is_published {
				text-align: center;
			}
		}
	}
}

.event-dates {
	margin: 0;
	opacity: 0.7;
	font-weight: normal;
	font-size: 0.9em;
}
// }
