.app-container.draamattupiiri:not(.admin) {
	--background: var(--primary-lilac);

	.cog-menu svg path {
		fill: var(--primary-white);
	}
}

$c-darkblue: #290c89;
$c-blue: #4636f1;
$c-text: #fff;
$c-pink: #f6a8fc;
// $c-pinkish: #faa4ff;
$c-pinkish: #f9a4ff;
$v-radius: 1em;

$c-darkish: rgba(0, 0, 0, 0.05);
$c-dark: rgba(0, 0, 0, 0.1);
$c-light: rgba(200, 250, 250, 0.1);

.c-stat {
	line-height: 1.2;
	margin-bottom: var(--font-size-5);
	.stat-value {
		font-size: var(--font-size-5);
		font-weight: bold;
	}

	.stat-label {
		text-transform: uppercase;
		font-size: var(--font-size-1);
		font-weight: bold;
		opacity: 0.5;
	}
}

.c-users-list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 32px;
	list-style: none;
	margin-top: 1em !important;

	li {
		font-size: var(--font-size-1);
		opacity: 0.5;
	}
}

.c-draamattupiiri {
	padding-bottom: 4em;



	.widelayout {
		display: grid;
		align-items: start;
		gap: 2em;
		justify-content: center;
		// direction: rtl;

		.user-list {
			top: 1em;
			position: sticky;
			display: none;
		}

		.target-user-list .c-users-list {
			button {
				background: none;
				border: none;
				margin:0;
			}
		// 	& > div {
		// 		position: relative;
		// 		z-index: 1;
		// 		background-color: var(--background);
		// 		border-radius: var(--radius-1);
		// 		padding: 1em var(--font-size-1);
		// 	}

		// 	&.open:before {
		// 		content: "";
		// 		display: block;
		// 		position: absolute;
		// 		inset:0;
		// 		background-color: rgba(0,0,0,0.2);
		// 	}
		}

		@media (min-width: $large) {
			// grid-template-columns: repeat(3, 1fr);
			// direction: ltr;
			grid-template-columns: 2fr 3fr 2fr;

			.user-list {
				display: block;
			}
		}
	}

	// .c-snazzymenu, .c-iconmenu, .c-ig-feed {
	// 	@media (min-width: $medium) {
	// 		position: sticky;
	// 		top:1em;
	// 	}
	// }

	.stat-column {
		display: flex;
		justify-content: center;

		& > div {
			flex-direction: column;
			align-items: flex-start;
		}
	}

	/* old styles */

	ul,
	ol {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	.icon {
		height: 1em;

		&.bubble {
			vertical-align: sub;
		}
		&.showmore {
			height: auto;
			width: 2em;
			opacity: 0.5;
		}
	}

	textarea,
	textarea:focus {
		// font-size: calc(10px + 2vmin);
		// font-family: $f-text;
		font-size: 1em;
		padding: 2em;
		display: block;
		width: 100%;
		// background-color: $c-dark;
		border: 0;
		border-radius: var(--radius-1);
		// border-radius: $v-radius;
		// color: #ff;
		color: var(--primary-dark);
		min-height: 10em;

		&::placeholder {
			// color: #fff;
			color: var(--background);
			opacity: 0.5;
		}
	}

	button {
		margin: 1em auto;

		&.add-new {
			font-size: 1em;
			// font-family: $f-text;
			padding: 1em 4em;
			border: none;
			border-radius: $v-radius;
			font-weight: 600;
			cursor: pointer;

			
			width: 100%;
		}

		&.m-notabtn {
			// font-size: 0.8em;
			background: none;
			color: inherit;
			// opacity: 0.6;
			// font-weight: 400;
			margin: 0em;
			padding: 2em 4em;
  			border: none;
		}

		&:not(.m-notabtn):hover {
			cursor: pointer;
			box-shadow: 0em 0em 1em rgba(0, 0, 0, 0.1);
			// background-color: $c-pink;
		}

		&[disabled] {
			// background-color: $c-dark !important;
		}
	}



	.search {
		// background: linear-gradient(45deg, rgb(58 46 190), rgb(53 37 224));
		background-color: rgba(250,250,250,0.2);
		border-radius:var(--radius-1);
		width: 100%;
		margin: 1em 0em;

		display: flex;
		flex-direction: column;

		box-shadow: 0 1em 1em rgba(0, 0, 0, 0);
		transition: all 0.3s ease;

		.search-user {
			cursor: pointer;
		}

		input {
			padding: 0.5em;
			color:var(--theme);
			font-size: 1.2em;
			
			border: none;
			border-radius: 1px solid rgba(200, 250, 250, 0.5);
			background: linear-gradient(
				15deg,
				rgba(0, 0, 0, 0.2),
				rgba(10, 10, 10, 0.1)
			);

			&::placeholder {
				color:var(--theme);
			}
		}

		p {
			margin: 0.6em 0;
			// &:hover:before {
			// 	content: '› ';
			// 	font-size: 1em;
			// 	line-height: 1;
			// 	display: inline;
			// }
		}

		button {
			width: 100%;
			text-transform: uppercase;
			opacity: 1;
			font-weight: 600;

			&:hover  {
				text-decoration: underline;
			}
		}

		&.open {
			box-shadow: 0 1em 1em rgba(0, 0, 0, 0.1);
			button {
				font-weight: bold;
			}
		}

		ul {
			width: 80%;
			margin: 1em auto;
			text-align: left;
		}
	}

	.comment-modal {
		text-align: center;
		margin: 0 auto;
		position: absolute;
		inset: 0;
		background: var(--primary-lilac);
		padding: var(--font-size-5);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h3 {
			span {
				display: block;
				color:var(--primary-rosa);
			}
		}

		p {
			padding-left: 10%;
			padding-right: 10%;
		}
		
		.close-modal {
			all:unset;
			position: absolute;
			top: 1em;
			right: 1em;
			font-size: 1.5em;
			cursor: pointer;
			opacity: 0.7;
			transition: all 0.3s ease-out;

			&:hover {
				opacity: 1;
			}
		}
	}

	.user-card {
		--card-bg: rgba(200, 250, 250, 0.1);
		--card-color: var(--primary-white);

		cursor: pointer;
		background-color: var(--card-bg);
		border-radius: var(--radius-1);
		padding: 1em;
		margin-bottom: 0.75em;
		text-align: left;
		transition: all 0.15s ease-out;
		border: 2px solid #faa4ff00;
		scroll-margin-top: 5em; /* whatever is a nice number that gets you past the header */

		&:hover {
			// border: 2px solid $c-pinkish;
		}

		p {
			color: var(--card-color);
		}

		.card-stats,
		.user-name {
			transition: all 0.15s ease-out;
		}

		.user-name {
			color: var(--card-color);
			// font-size: 0.8em;
			margin-top: 0;
			font-weight: 800;
		}

		&.current-user {
			margin: 1em -0.5em;
			box-shadow: 0em 0.2em 0.5em rgba(0, 0, 0, 0.3);
			border: 2px solid #faa4ff;

			.user-name {
				opacity: 1;
			}
		}

		ul {
			margin: 0.5em -1em;
		}
		li {
			border-top: 1px solid #4636f124;
			padding: 1em;

			p {
				margin: 0;
			}
		}

		.card-stats {
			display: flex;
			justify-content: space-between;
			align-items: center;
			opacity: 1;

			.card-add {
				width: 2.1em;
				height: 1em;
				// color: $c-darkblue;
				line-height: 0.9em;
				cursor: pointer;
				// background-color: $c-pinkish;
				// border-radius: 50%;
				text-align: right;
				// vertical-align: top;
				display: block;
				
				img {
					transform-origin: 50% 50%;
					transition: all 0.33s cubic-bezier(0.23, 1, 0.32, 1);
					margin:0;
					margin-left: auto;
					display: block;
				}
				&:hover {

					img {
						transform: scale(1.3);
					}
					// filter: drop-shadow(0em 0.1em 1em rgba(0, 0, 0, 0.91));
				}
			}

			.card-messagecount {
				font-size: 0.7em;
				opacity: 0.4;
				.icon {
					height: 1.3em;
					margin-right: 0.25em;
				}
				.heart {
					margin-left: 0.25em;
				}
			}
		}

		&.m-open {
			--card-bg: var(--primary-white);
			--card-color: var(--primary-dark);
			background-size: contain;

			margin: 0em -0.5em 0.75em;
			box-shadow: 0em 0.5em 3em rgba(0, 0, 0, 0.1);

			.user-name {
				color: var(--primary-lilac);
				opacity: 0.7;
			}

			li {
				padding: 1.2em 1em;
				padding-right: 3em;
			}

			.card-stats {
				opacity: 1;

				.card-messagecount {
					opacity: 1;
					color: var(--card-color);
				}
			}

			.comment {
				white-space: pre-wrap;
				p {
					max-height: none;
					overflow: visible;
					&:after {
						display: none;
						// background: linear-gradient(0deg, #faa4ffff, #4030d800);
					}
				}
			}
		}

		.comment {
			position: relative;
			// padding-top: 0.5em;
			// font-size: 0.95em;
			line-height: 1.3;
			word-break: break-word;

			p {
				font-size: 1em;
				max-height: 7em;
				overflow: hidden;

				&:after {
					content: ' ';
					position: absolute;
					bottom: 0;
					height: 20%;
					width: 100%;
					// background: linear-gradient(0deg, #4030d8ff, #4030d800);
					animation: fadeIn 0.3s 1 ease-in both;
					left: 0;
					opacity: 0;
				}
			}

			

			.timeago,
			.heart {
				// position: absolute;
				// top: -1em;
				right: 0;
				font-size: 0.7em;

				// color: #290c89;
				color: var(--card-color);
				opacity: 0.5;

				.user-card.m-open & {
					// top: -1.3em;
				}
			}

			.heart {
				// left: 0;
				position: absolute;
				top: 0%;
				right: -3.5em;
				width: 3em;
				height: 3em;

				background: url(../../../images/draamattupiiri_icons/heart_empty.svg);
				background-size: contain;
				background-repeat: no-repeat;

				display: flex;
				justify-content: center;
				align-items: center;

				// color: #fff;
				color: var(--primary-rosa);

				opacity: 0.75;

				span {
					// margin-top: -0.3em;
					display: block;
					text-align: center;
					flex: 1 1 auto;
					font-size: 1.25em;
				}

				&.liked {
					opacity: 1;
					background-image: url(../../../images/draamattupiiri_icons/heart_full.svg);
					color: var(--card-bg);
				}
			}
		}
	}
}

// $c-darkblue: #290c89;
// $c-blue: #4636f1;
// $c-text: #fff;
// $c-pink: #f6a8fc;
// // $c-pinkish: #faa4ff;
// $c-pinkish: #f9a4ff;

// $c-darkish: rgba(0, 0, 0, 0.05);
// $c-dark: rgba(0, 0, 0, 0.1);
// $c-light: rgba(200, 250, 250, 0.1);

// $f-cursive: 'Pacifico', serif;
// $f-text: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
// 	'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
// 	sans-serif;

// $v-radius: 1em;

// body {
// 	margin: 0;
// 	font-family: $f-text;
// 	-webkit-font-smoothing: antialiased;
// 	-moz-osx-font-smoothing: grayscale;

// 	background-color: $c-blue;
// 	// background: url(../Images/pattern-ystis-pale.png) repeat, linear-gradient(0deg, #3122cb 0%, $c-blue 3%);
// 	color: $c-text;
// 	text-align: center;
// 	font-size: calc(9px + 1.5vmin);
// }

// .App {
// 	max-width: 1200px;
// 	margin: 0 auto;
// }

// h1 {
// 	font-size: 2.5em;
// }

// h1,
// h2 {
// 	font-family: $f-cursive;
// 	font-weight: normal;
// }

// p {
// 	font-size: 1.2em;
// 	line-height: 1.5;
// }

// strong {
// 	font-weight: 800;
// }

// header,
// textarea,
// div {
// 	box-sizing: border-box;
// }

// 	.card-stats {
// 		display: flex;
// 		justify-content: space-between;
// 		align-items: center;
// 		opacity: 1;

// 		.card-add {
// 			width: 2.1em;
// 			height: 1em;
// 			// color: $c-darkblue;
// 			line-height: 0.9em;
// 			cursor: pointer;
// 			// background-color: $c-pinkish;
// 			// border-radius: 50%;
// 			text-align: right;
// 			vertical-align: top;
// 			display: inline-block;

// 			// &:hover {
// 			// 	// background: $c-pinkish;
// 			// }
// 		}

// 		.card-messagecount {
// 			font-size: 0.7em;
// 			opacity: 0.4;
// 			.icon {
// 				height: 1.3em;
// 				margin-right: 0.25em;
// 			}
// 			.heart {
// 				margin-left: 0.25em;
// 			}
// 		}
// 	}

// 	&.m-open {
// 		// background: url(../Images/pattern-ystis.svg) repeat $c-pinkish;
// 		background-color: $c-pinkish;
// 		background-size: contain;
// 		color: $c-darkblue;
// 		margin: 0em -0.5em 0.75em;

// 		.user-name {
// 			color: $c-darkblue;
// 			opacity: 0.7;
// 		}

// 		li {
// 			padding: 1.2em 1em;
// 			padding-right: 3em;
// 			// padding-top: 1.6em;
// 		}

// 		.card-stats {
// 			opacity: 1;

// 			// .card-add {
// 			// color: $c-pinkish;
// 			// background-color: $c-darkblue;
// 			// }
// 			.card-messagecount {
// 				color: #fff;
// 				opacity: 1;
// 			}
// 		}
// 	}
// }

// .comment {
// 	position: relative;
// 	// padding-top: 0.5em;
// 	// font-size: 0.95em;
// 	line-height: 1.3;
// 	word-break: break-word;

// 	p {
// 		font-size: 1em;
// 		max-height: 7em;
// 		overflow: hidden;

// 		&:after {
// 			content: ' ';
// 			position: absolute;
// 			bottom: 0;
// 			height: 20%;
// 			width: 100%;
// 			background: linear-gradient(0deg, #4030d8ff, #4030d800);
// 			animation: fadeIn 0.3s 1 ease-in both;
// 			left: 0;
// 			opacity: 0;
// 		}
// 	}

// 	.user-card.m-open & {
// 		white-space: pre-wrap;
// 		p {
// 			max-height: none;
// 			overflow: visible;
// 			&:after {
// 				display: none;
// 				background: linear-gradient(0deg, #faa4ffff, #4030d800);
// 			}
// 		}
// 	}

// 	.timeago,
// 	.heart {
// 		// position: absolute;
// 		// top: -1em;
// 		right: 0;
// 		font-size: 0.7em;
// 		color: #fff;

// 		color: #290c89;
// 		opacity: 0.5;

// 		.user-card.m-open & {
// 			// top: -1.3em;
// 		}
// 	}

// 	.heart {
// 		// left: 0;
// 		position: absolute;
// 		top: 0%;
// 		right: -3.5em;
// 		width: 3em;
// 		height: 3em;

// 		background: url(../Images/icons/heart_empty.svg);
// 		background-size: contain;
// 		background-repeat: no-repeat;

// 		display: flex;
// 		justify-content: center;
// 		align-items: center;

// 		color: #fff;

// 		opacity: 0.75;

// 		span {
// 			margin-top: -0.3em;
// 			display: block;
// 			text-align: center;
// 			flex: 1 1 auto;
// 			font-size: 1.25em;
// 			font-family: $f-cursive;
// 		}

// 		&.liked {
// 			opacity: 1;
// 			background-image: url(../Images/icons/heart_full.svg);
// 			color: $c-pink;
// 		}
// 	}
// }

// .cardlist {
// 	transition: all 0.3s ease-out;

// 	.separator {
// 		opacity: 0.33;
// 		font-size: 0.5em;
// 	}
// }

// .year-review {

// 	padding: 2em;
// 	width: 100%;
// 	border-radius: 1em;
// 	// background-color: rgba(0, 0, 0, 0.15);
// 	background-color: #4030d8ff;
// 	margin-bottom: 1em;
// 	position: relative;
//   	overflow: hidden;

// 	h1 {
// 		cursor: pointer;
// 		margin: 0em;
// 		padding: 1em;
// 		margin: 0em;
// 		// color: $c;
// 		transition: all 0.3s ease-in-out;

// 		display: flex;
// 		flex-direction: row;
// 		justify-content: center;
// 		align-items: center;
// 		align-content: center;
// 		flex-wrap: nowrap;

// 		&:hover {
// 			color:#fff;
// 		}

// 	}

// 	.logo {
// 		// margin-bottom: -0.2em;
// 		// margin-right: 0.2em;
// 		// // margin-left: -1em;
// 		// height: 0.7em;
// 		position: absolute;
// 		opacity: 0.025;
// 		width: 45%;
// 		left: -1em;
// 		bottom: -1em;
// 		user-select: none;
// 		pointer-events: none;
// 	}

// 	&:hover, &:focus-within {
// 		margin-left: -0.5em;
// 		// background-color: rgba(0, 0, 0, 0.2);
// 		// margin-top: -0.5em;
// 	}
// 	&.active {
// 		// overflow: visible;
// 		padding: 2em;
// 		background-color: deeppink !important;
// 		box-shadow: 1em 1em 0em rgba(0, 0, 0, 0.2);
// 		margin-left: -0.5em;
// 		margin-bottom: 3em;
// 		// margin-top: -1em;
// 	}

// 	canvas {
// 		position: fixed !important;
// 		width: 100vw !important;
// 	}

// 	.comment p {
// 		height: auto;
// 		max-height: auto;
// 		overflow: visible;

// 		&::after {
// 			display: none;
// 		}
// 	}

// 	h3 {
// 		text-align: left;
// 		text-shadow: .1em 0.1em 0em rgba(0,0,0,0.2);
// 	}

// 	& > ul > li {
// 		margin-bottom: 2em;

// 		& > button {
// 			display: flex;
// 			display: flex;
// 			flex-direction: row;
// 			align-content: center;
// 			align-items: center;
// 			justify-content: center;
// 			.icon {
// 				margin-right: 0.5em;
// 				margin-left: -0.5em;
// 			}
// 		}
// 	}

// 	.stats {
// 		h3 {
// 			display: flex;
// 			flex-direction: column;
// 			flex-wrap: wrap;
// 			align-content: flex-start;
// 			align-items: flex-start;
// 		}

// 		span {
// 			font-size: 1.66em;
// 			text-shadow: none;
// 			background-color: deeppink;
// 			color:#fff;
// 			background-image: linear-gradient(130deg, $c-pinkish 70%, $c-pink);
// 			background-image: linear-gradient(130deg, #ffffff 70%, #f6a8fc);
// 			// background-image: linear-gradient(136deg, #fee8ff 70%, #faadff);
// 			background-size: 100%;
// 			-webkit-background-clip: text;
// 			-moz-background-clip: text;
// 			-webkit-text-fill-color: transparent;
// 			-moz-text-fill-color: transparent;
// 		}
// 	}

// 	.best-comment {
// 		.heart {
// 			right: 0em;
// 			opacity: 1;
// 		}
// 		.timeago {
// 			color: #fff;
// 		}
// 		.message {
// 			font-weight: bold;
// 			margin-right: 4em;

// 			&::after {
// 				content: close-quote;
// 				display: inline;
// 				background: none;
// 				position: relative;
// 				height: auto;
// 				margin-left: 0.1em;
// 				font-weight: bold;
// 			}

// 			&::before {
// 				content: open-quote;
// 				margin-right: 0.1em;
// 				font-weight: bold;
// 			}
// 		}
// 	}

// 	.user-name {
// 		opacity: 1;
// 	}

// 	.card-stats {
// 		& > * {
// 			opacity: 1 !important;
// 		}
// 	}

// 	.card-toggle {
// 		display: none;
// 	}

// }

// .ad {
// 	// margin: 1em -1.3em;
// 	display: block;
// 	img {
// 		max-width: 100%;
// 		display: block;
// 		margin: 0 auto;
// 	}
// }

// .m-loading {
// 	opacity: 0.5;
// }

// .spinner {
// 	font-size: 2em;
// 	background-color: rgba(0, 0, 0, 0.2);
// 	position: fixed;
// 	top: 0;
// 	left: 0;
// 	width: 100%;
// 	height: 100%;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	z-index: 100;
// }

// .search {
// 	background: $c-light;
// 	// background: linear-gradient(
// 	// 	45deg,
// 	// 	rgba(100, 150, 150, 0.2),
// 	// 	rgba(200, 250, 250, 0.1)
// 	// );
// 	background: linear-gradient(45deg, rgb(58 46 190), rgb(53 37 224));
// 	width: 100%;
// 	border-radius: $v-radius;
// 	margin: 1em;

// 	display: flex;
// 	flex-direction: column;

// 	box-shadow: 0 1em 1em rgba(0, 0, 0, 0);
// 	transition: all 0.3s ease;

// 	.search-user {
// 		cursor: pointer;
// 	}

// 	input {
// 		padding: 0.5em;
// 		color: #fff;
// 		font-size: 1.2em;
// 		font-family: $f-text;
// 		// background-color: rgba(0, 0, 0, 0.2);
// 		// border-bottom:1px solid
// 		border: none;
// 		border-radius: 1px solid rgba(200, 250, 250, 0.5);
// 		background: linear-gradient(
// 			15deg,
// 			rgba(0, 0, 0, 0.2),
// 			rgba(10, 10, 10, 0.1)
// 		);
// 	}

// 	p {
// 		margin: 0.6em 0;
// 		&:hover:before {
// 			content: '› ';
// 			font-size: 1em;
// 			line-height: 1;
// 			display: inline;
// 		}
// 	}

// 	button {
// 		width: 100%;
// 		text-transform: uppercase;
// 		opacity: 1;
// 		font-weight: 600;

// 		&:hover  {
// 			text-decoration: underline;
// 		}
// 	}

// 	&.open {
// 		box-shadow: 0 1em 1em rgba(0, 0, 0, 0.1);
// 		button {
// 			font-weight: bold;
// 		}
// 	}

// 	ul {
// 		width: 80%;
// 		margin: 1em auto;
// 		text-align: left;
// 	}
// }

// .comment-form {
// 	min-width: 100%;
// 	h2 {
// 		.icon {
// 			margin-right: 0em;
// 			// margin-left: -1em;
// 			vertical-align: sub;
// 		}
// 		margin-left: 1em;
// 		text-align: left;
// 	}
// }

// form {
// 	&.disabled {
// 		opacity: 0.5;
// 	}
// }

// .debug {
// 	opacity: 0.3;
// 	font-size: 0.75em;
// }

// footer {
// 	p {
// 		font-size: 0.75em;
// 		// color: $c-darkblue;
// 		opacity: 0.5;
// 	}

// 	.external-links {
// 		opacity: 1;
// 		color: #fff;
// 		margin-bottom: 2em;

// 		a {
// 			color: #fff;
// 		}
// 	}

// 	button {
// 		opacity: 1 !important;
// 	}

// 	margin-top: 20vh;
// 	padding: 2em;
// 	background-color: $c-darkish;
// 	background: linear-gradient(180deg, rgba(0, 0, 0, 0.075), rgba(0, 0, 0, 0));
// }
// /* --------- */

.spinner {
	transition: all 10s ease;
	opacity: 0;
	& > div {
		width: 1em;
		height: 1em;
		background-color: #fff;

		border-radius: 100%;
		display: inline-block;
		-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	}

	.bounce1 {
		-webkit-animation-delay: -0.64s;
		animation-delay: -0.64s;
	}

	.bounce2 {
		-webkit-animation-delay: -0.48s;
		animation-delay: -0.48s;
	}

	.bounce3 {
		-webkit-animation-delay: -0.32s;
		animation-delay: -0.32s;
	}

	.bounce4 {
		-webkit-animation-delay: -0.16s;
		animation-delay: -0.16s;
	}
}

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

/* Bounce In */
.hvr-bounce-in {
	display: inline-block;
	vertical-align: middle;
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
	transition-duration: 0.5s;
}
.hvr-bounce-in:hover,
.hvr-bounce-in:focus,
.hvr-bounce-in:active {
	transform: scale(1.2);
	transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

@keyframes wobble {
	25% {
		transform: rotate(15deg);
	}
	50% {
		transform: rotate(-30deg);
	}
	75% {
		transform: rotate(5deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
@keyframes sunrays {
	0% {
		// transform: rotateY(0deg) rotateX(360deg);
		transform: rotateZ(0deg);
	}
	100% {
		// transform: rotateY(360deg) rotateX(0deg);
		transform: rotateZ(360deg);
	}
}

@keyframes wobble2 {
	25% {
		transform: rotate(15deg);
	}

	50% {
		transform: rotate(-22deg);
	}

	75% {
		transform: rotate(5deg);
	}

	100% {
		transform: rotate(0deg);
	}
}

#Rays {
	// transform: rotateZ(-425deg);
	perspective: 1000px;
	transform-origin: 50% 50%;
	animation: sunrays 8.5s linear;
	animation-iteration-count: infinite;
	// animation-direction: alternate;
}
#Face_Glasses {
	// transform: rotateZ(-425deg);
	// perspective: 1000px;
	// transform-origin: 50% 50%;
	// animation: wobble2 3.5s ease-in-out;
	// animation-iteration-count: infinite;
	// animation-direction: alternate-reverse;
	// animation-direction: alternate;
}
