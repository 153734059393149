* {
	box-sizing: border-box;
}
a {
	cursor: pointer;
}

body,
html {
	height: 100%;
	min-height: 100%;
	font-family: $f-basic;
	font-weight: 400;
	font-style: normal;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

main,
#root {
	width: 100%;
	// height: 100%;
	min-height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
	// color: $c-text;
	z-index: 1;
}

#root {
	height: auto;
}

main {
	flex-grow: 1;
	justify-content: center;
	padding: 2em;
	// justify-content: flex-start;
}

img {
	display: block;
	max-width: 100%;
}
// h1 {
// 	font-family: $f-bold;
// 	font-size: 2.5em;
// 	font-weight: normal;
// 	letter-spacing: 0;
// 	// line-height: 1.6;
// 	// margin-top: 0;
// 	// margin-bottom: 0;
// 	color: $c-text;

// 	@include min($medium) {
// 		font-size: 3em;
// 	}
// }
// h2 {
// 	font-family: $f-bold;
// 	font-weight: normal;
// 	font-style: normal;
// 	color: $c-text;
// 	font-size: 2em;

// 	@include min($medium) {
// 		font-size: 2.3em;
// 	}
// }

// h1,
// h2 {
// 	// font-size: 2em;

// 	&:after {
// 		// content: '';
// 		display: block;
// 		width: 192px;
// 		height: 6px;
// 		/* margin-top: 3.5em; */
// 		// background: url(https://100syyta.fi/resources/images/wave.svg) no-repeat left top;
// 		margin: 0 auto;
// 		top: 0.5em;
// 		position: relative;
// 	}
// }

// h3 {
// 	font-size: 1.4em;
// 	// text-align: center;
// 	// margin: 0 auto;
// 	margin-left: auto;
// 	margin-right: auto;
// }

p {
	line-height: 1.5;
}

// ul {
// 	list-style: none;
// }

// ul {
// 	list-style: none;
// 	margin: 0;
// 	padding: 0;
// 	display: flex;
// 	flex-direction: row;
// 	flex-wrap: wrap;
// 	li {
// 		padding: 1em;
// 	}
// }

a {
	text-decoration: none;
	&:focus,
	&:active,
	&:hover {
		text-decoration: underline;
	}
}

/* ---- */

.grid-center-h {
	display: 'flex';
	justify-content: 'center';
}

.grid-narrow {
	max-width: $medium;
	margin: 0 auto;
}
.grid-front {
	max-width: $form-width;
	margin: 0 auto;
}

.grid-2 {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	@include min($medium) {
		flex-direction: row;
		& > * {
			flex-basis: calc(50% - 1em);
			margin: 0em 0.5em;
		}
	}
}

.grid-flex {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;

	@include min($medium) {
		flex-direction: row;

		&.two {
			& > * {
				flex-basis: calc(50% - 0.5em);
				margin: 0em 0.5em;
				&:first-child {
					margin-left: 0;
				}
				&:last-child {
					margin-right: 0;
					flex: 1 1 auto;
				}
			}
		}

		&.panel-right {
			flex-grow: 1;
			margin-left: -2em;
			margin-right: -2em;
			width: calc(100% + 4em);
			min-height: 100%;

			& > * {
				min-height: 100%;
				display: flex;
				justify-content: stretch;
				align-items: stretch;

				&:last-child {
					margin-right: 0;
					flex-basis: 25%;
					// background: salmon;
				}

				&:first-child {
					margin-left: 0;
					flex-basis: 75%;
					// background: teal;

					border-right: 1px solid #eee;
				}

				& > * {
					flex-grow: 1;
				}
			}
		}

		&.panel-left {
			flex-grow: 1;
			margin-left: -2em;
			margin-right: -2em;
			width: calc(100% + 4em);
			min-height: 100%;
			& > * {
				min-height: 100%;
				display: flex;
				justify-content: stretch;
				align-items: stretch;

				&:first-child {
					margin-left: 0;
					flex-basis: 25%;

					border-right: 1px solid #eee;
				}
				&:last-child {
					margin-right: 0;
					flex-basis: 75%;
				}

				& > * {
					flex-grow: 1;
				}
			}
		}
	}
}

.gray {
	background-color: $c-verylightfade;
}

// .loginContainer {
// 	// min-height: 100%;
// 	// min-height: 100vh;
// 	width: 100%;
// 	// max-width: $medium;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	text-align: left;

// 	.loginForm {
// 		width: auto;
// 		margin: 0 auto;
// 		min-width: 20em;
// 		padding-bottom: 3em;
// 	}

// 	input[type='submit'] {
// 		margin-top: 1.5em;
// 		text-transform: uppercase;
// 	}

// 	label {
// 		text-align: left;
// 		font-weight: bold;
// 		color: $c-text;
// 	}

// 	.login-issues {
// 		margin-top: 4em;
// 		text-align: left;
// 	}
// }
