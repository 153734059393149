.app-container.feels:not(.admin),
.app-container.feedback:not(.admin) {
	--background: var(--secondary-green);

	.cog-menu svg path {
		fill: var(--primary-white);
	}
}

.variant-mini .split-container .c-form {
	background: transparent;
	text-align: left;
	margin: 0;
	padding: 0;

	h3, h4 {
		font-size: var(--font-size-0);
		font-weight: bold;
	}

	&:not(form) {
		text-align: center;
	}

	.buttons {
		margin-bottom: 0em;
		margin-top: 0.5em;

		text-align: center !important;
	}
}

.split-container {
	display: flex;
	flex-direction: column;

	.content,
	.c-form {
		flex: 1 1 100%;
	}

	.c-form {
		margin: 2em auto;
		max-width: none;
	}

	@media (min-width: $large) {
		flex-direction: row;

		.content {
			padding-right: 4em;
		}

		.c-form {
			flex: 1 1 60%;

			.buttons {
				text-align: left;
			}
		}
		.content {
			flex: 0 1 40%;
		}
	}

	.c-form {
		// h1.article-title {
		// 	color: var(--accentcolor);
		// 	// font-size: 3rem;
		// 	font-size:var(--font-size-5);
		// 	font-style: normal;
		// 	font-weight: 700;
		// 	line-height: normal;
		// 	letter-spacing: 0.0625rem;
		// 	text-transform: uppercase;
		// 	margin-top:0;
		// }

		--theme: var(--primary-dark);
		border-radius: 1.5rem;
		background: white;
		color: var(--primary-dark);

		padding: var(--container-padding);
		// margin: calc(-1 * var(--container-padding));
		// margin-top:1em;
		// margin-bottom:1em;

		@media (min-width: $medium) {
			// margin:0;
			padding: 4rem 7.5rem;
		}
	}
}

.app-container.feels:not(.admin) {
		
	.split-container {
		@media (min-width: $large) {
			flex-direction: row;

			.content:first-child {
				padding-right: 4em;
				flex: 1 1 40%;
			}
		}

	}
}


.all-the-feels {
	.results-overview {
		ul {
			padding: 0;
			margin: 0;
		}
	}

	#graph-numbers {
		// width: 100%;
		min-width: 200px;
	}

	.results-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
	}

	.results-overall ul {
		margin-bottom: 2em;
	}

	.results-overall li, 
	.graph-number {
		display: flex;
		align-items: center;
		flex-direction: row;
		font-size: var(--font-size--1);
		margin-bottom: 14px;
	}

	.results-overall li::before {
		content: "";
		width: 12px;
		height: 12px;
		position: relative;
		display: inline-block;
		margin-right: 10px;
		border-radius: 50%;
		background-color: #f7575b;
	}

	.results-overall li {
		&:before {			
			background-color: #333;
		}
		span {
			margin-right: 1ch;
			font-weight: bold;
			flex-basis: 40px;
			font-weight: 600;
			text-align: right;
			margin-right: 15px;
		}	
	}

	.graph-number:before {
		content: "";
		width: 12px;
		height: 12px;
		position: relative;
		display: inline-block;
		margin-right: 10px;
		border-radius: 50%;
		background-color: #f7575b;
	}
	.graph-number:nth-child(1):before {
		background-color: #f7575b;
	}
	.graph-number:nth-child(2):before {
		background-color: #fcabad;
	}
	.graph-number:nth-child(3):before {
		background-color: #d4d4dc;
	}
	.graph-number:nth-child(4):before {
		background-color: #9293a7;
	}
	.graph-number:nth-child(5):before {
		background-color: #28284f;
	}

	.graph-number span {
		flex-basis: 40px;
		font-weight: 600;
		text-align: right;
		margin-right: 15px;
	}
}
