
*, *::before, *::after {
	box-sizing: border-box;
}

html {
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	background: #fff;
	margin: 0;
	font: 100%/1 sans-serif;
	// -moz-osx-font-smoothing: grayscale;
	// -webkit-font-smoothing: antialiased;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

button {
	cursor: pointer;
	-webkit-appearance: button;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="submit"]:-moz-focusring {
	outline: 1px dotted;
}

img {
	max-width: 100%;
	height: auto;
	border: 0;
}

button, select, input, textarea {
	font: inherit;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

ul, ol {
	margin: 0;
	padding: 0;
}

nav ul {
	list-style: none;
}

figure {
	margin: 0;
}

*[hidden] { display: none; }

@media screen and (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0s !important;
		transition: none !important;
		scroll-behavior: auto !important;
	}
}
