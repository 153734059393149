/* Overlay background for the drawer */
.drawer-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999;
	display: flex;
	justify-content: flex-end;
	opacity: 0;
	transition: opacity 0.3s ease; /* Fade effect */
	pointer-events: none; /* Prevent interaction when invisible */

	&.open {
		opacity: 1;
		pointer-events: all; /* Enable interaction when visible */
	}
}

/* Drawer styling */
.drawer {
	position: relative;
	top: 0;
	right: 0;
	height: 100%;
	width: min(32em, 100vw);
	background-color: white;
	overflow-x: hidden;
	transition: all 0.3s ease;
	z-index: 1000; /* In front of the overlay */
	box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
	padding: 2rem;
	color: var(--primary-dark);
	transform: translateX(100%);

	background-color: color-mix(in srgb, var(--secondary-blue), transparent 30%);
	color: #fff;
	backdrop-filter: blur(12px);

	&.open {
		transform: translateX(0); /* Slide into view */
	}

	#drawer-title {
		margin: -2rem -2rem 0em -2rem;
		padding: 2rem;
		font-size: var(--font-size-1);
		// background-color: rgba(250, 250, 250, 0.2);
		background: linear-gradient(180deg, rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0));
	}


}

.close-btn {
	position: absolute;
	top: 1rem;
	right: 1rem;
	background: none;
	border: none;
	font-size: 3em;
	cursor: pointer;
	color: #fff;
}

body:has(.drawer.open) {
	overflow: hidden;
}

.drawer-overlay.profile-drawer {

	.buttons {
		display: flex;
		justify-content: space-between;
		margin-top: 2em;
	}

	.picture-selector {
		width: 100%;
		margin: 0;

		input {
			visibility: hidden;
			display: none;
		}
	}

	legend {
		font-size: var(--font-size--1);
		font-weight: bold;
		text-transform: uppercase;
		display: block;
		text-decoration: underline;
		// margin-bottom: 1em;
	}

	fieldset {
		border: none;
		padding: 0;
		// margin: 2em 0em;
		margin-top: var(--font-size-2);
		margin-bottom: var(--font-size-2);
		padding-bottom: var(--font-size-2);
		border-bottom: 1px solid rgba(220, 220, 220, 0.2);
	}


	.label {
		// opacity: 0.4;
		// text-transform: uppercase;
		// color: #fff;
		font-weight: 600;
		text-align: left;
		// letter-spacing: 0.5px;
		display: block;
		margin: var(--font-size-0) 0em;
		margin-bottom: 0;
	}

	.required.label,
	.required > .label {
		// font-weight: bold;
		// opacity: 0.7;
		&:after {
			content: '*';
			display: inline;
		}
	}
	label {
		display: block;

		&.label-line {
			input,
			textarea {
				border-top: none;
				border-left: none;
				border-right: none;
			}
		}
	}


	.checkboxesGroup {
		display: flex;
		flex-wrap: wrap;
		text-align: left;

		.checkbox {
			flex-shrink: 1;
			flex-basis: 100%;
			padding-right: 1em;

			input {
				min-width: var(--font-size-0);
  				aspect-ratio: 1 / 1;
			}

			@include min($small) {
				flex-basis: 50%;
			}
		}

		&.full-wide {
			.checkbox {
				flex-basis: 100%;
			}
		}

		.option-sublabel {
			opacity: 0.5;
			display: block;
		}
	}


	input,
	textarea,
	select {
		width: 100%;
		display: block;
		// border: 1px solid rgba(120, 120, 120, 0.2);
		border: 1px solid var(--gray);
		border-radius: 3px;
		box-sizing: border-box;

		padding: 1.1em 0.8em;
		outline: none;
		background: none;
		background-color: rgba(255, 255, 255, 0.95);

		color: black;

		font-size: 0.9em;
		font-family: var(--font-regular);

		&:hover {
			box-shadow: 0 9px 4px -6px rgba(0, 0, 0, 0.07);
		}

		&:focus {
			border-color: var(--accentcolor);
			box-shadow: 0 9px 4px -6px rgba(0, 0, 0, 0.07);
		}

		&:invalid {
			border-color: red;
		}

		&[disabled] {
			opacity: 0.5;
			background-color: rgba(245, 245, 245, 0.95);
		}
	}

	.skill-list {
		display: flex;
		margin: 1em 0em;
		gap: 0.5em;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
	}

	select {
		appearance: none;
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAAXNSR0IArs4c6QAAAJJJREFUGBljZgCCUE9PUW1VVe5rd+58A/HRQaBboJiumiIXSJ4RpPjXt3/7QYrYuJgcV2/f/hpZA7o8E1zy/39tkEaQApgYXDFQDibGCGKgSDAyXgXZBBIH2wxSDBUD2Q7WgE0TSIwBTTFICK4BxEGxCSwLsQ3ZXygaQGpAIfLv57d9IDa2QACJYwCQJmTPIysAAD4OWaVQRa3DAAAAAElFTkSuQmCC);
		background-position: right 1em center;
		background-size: 12px;
		background-repeat: no-repeat;
		background-color: #ffffff;
		transition-duration: 250ms;
		padding-right: 2.5em;
	}
	input,
	textarea,
	select,
	.ck.ck-reset.ck-editor {
		margin-top: 0.4em;
	}

	select {
		height: 3.75em;
	}

	textarea {
		min-height: 15em;
		line-height: 1.3;
	}
	.ck-editor__editable_inline {
		min-height: calc(7em - 0.4em);
	}

	label.checkbox {
		& > div {
			padding-top: 0;
			margin: 0;
			// margin-top: 0.4em;
			display: flex;
			align-items: flex-start;

			span {
				margin-top: 0.2em;
				text-align: left;
			}
		}
	}

	input[type='radio'],
	input[type='checkbox'] {
		width: 1.33em;
		height: 1.33em;
		display: inline-block;
		margin: 0 0.5em;
		margin-left: 0;
		margin-top: 0.25em;
		border: 2px solid var(--primary-dark);
		flex-shrink: 0;
	}



	.checkbox-label,
	.radio-label,
	.switcher {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 1em;

		input {
			margin: 0 0.5em;
			width: auto;
		}

		.switch {
			margin-right: 1em;
		}

		&.disabled {
			pointer-events: none;
			opacity: 0.4;
		}
	}

	.submit-holder {
		margin-bottom: 6em;
	}
}
