:root {
	// Fonts
	--font-regular: 'Monument Grotesk', 'Source Sans Pro', sans-serif;

	// Colors
	--primary-white: #fff;
	--primary-dark: #232025;
	--primary-rosa: #F5BBBF;
	--primary-orange: #FE7D62;
	--primary-lilac: #6E77C2;

	--secondary-gold: #faaa43; //new?
	--secondary-yellow: #FFBB01;
	--secondary-lgreen: #BECC86;
	--secondary-green: #12AEB6;
	--secondary-blue: #1E4AC6;
	--secondary-light-gray:#EFEFEF;
	--secondary-gray:#949FA8;

	--advent-red: #C8102E;

/* 	// ---
	--dusty-rose: #D98880;
	--muted-plum: #8E44AD;
	--golden-yellow: #FFD700;
	--deep-teal: #008080;
	--tertiary-teal: #00ADB5;
	--olive-green: #808000;
	// --- */

	--theme: var(--primary-white);
	--background: #38363a;

	--gray: #38363A;	// 3 uses
	--medium-gray: #e5e6e6; // 1 use
	--light-gray: #efefef; // 4 uses
	--very-light-fade: rgba(127, 127, 127, 0.05);
	// --strong: #252b70;

	--toolkit-gray: #4A444E;
	--accentcolor: var(--primary-orange);	

	--message-grey: #4C4A4E;
	--message-grey-gradient: linear-gradient(0deg, #4C4A4Eff, #4C4A4E00);
	// --message-purple: background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), #6E77C2;
	--gradient-lilac: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), var(--primary-lilac);


	--radius-1: #{em(16px)};
	--radius-2: #{em(24px)};
	--radius-3: #{em(32px)};
	--radius-4: #{em(40px)};


	// Misc
	--header-height: #{em(100px)};
	--header-height-small: #{em(60px)};

	// Container
	--default-container: #{rem(865px)};
	--container: #{rem(670px)};
	--container-wide: #{rem(1600px)};
	--container-padding: 1.5rem;
	// --container-padding: 1.5rem;
}

// Screen size breakpoints (for min-width queries)
$xsmall: em(320px);
$small: em(480px);
$medium: em(640px);
$large: em(980px);
$xlarge: em(1200px);
$xxlarge: em(1360px);
$xxxlarge: em(1600px);
$xxxxlarge: em(1920px);

// Screen size breakpoints (for max-width queries).
$small-max: $medium - em(1px);
$medium-max: $large - em(1px);
$large-max: $xlarge - em(1px);

// Navigation breakpoints
$desktop-nav: $medium;
$mobile-nav-max: $desktop-nav - em(1px);
