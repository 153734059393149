.c-article-nav {
	display: flex;
	border-top: 1px solid #cccedb;
	padding: 0em 2em;
	padding-top: 1.5em;
	width: 100%;
	justify-content: space-between;
	// @include container(var(--container));

	a {
		font-weight: 700;
		display: flex;
		align-items: center;
		max-width: em(200px);

		&.prev {
			margin-right: 2%;

			&::before {
				content: '';
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-right: 5px solid rgba(2, 5, 83, 0.5);
				display: inline-block;
				vertical-align: middle;
				margin-right: 0.5em;
			}
		}

		&.next {
			text-align: right;
			margin-left: auto;

			&::after {
				content: '';
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-bottom: 5px solid transparent;
				border-left: 5px solid rgba(2, 5, 83, 0.5);
				display: inline-block;
				margin-left: 0.5em;
			}
		}
	}
}
