.c-article {
	font-size:var(--font-size-0);
	margin-bottom: rem(50px);



	// h1 {
	// 	font-size: rem(31px);

	// 	@include container(var(--container), true);
	// }

	// & > div > * {
	// 	@include container(var(--container), true);
	// }

	// .alignwide {
	// 	max-width: var(--container-wide);
	// }

	// .alignfull {
	// 	max-width: none;
	// }

	@import "../layout/blocks";



	.wp-block-file__embed {
		display: none;
	}

	.wp-block-file a:not(.wp-element-button) {

		padding-left: 2em;

		&:before {
			content: "📎";
			margin-left: -2em;
			text-decoration: none;
			position: absolute;
			
		}	
	}

	.wp-block-file__button.wp-element-button {
		margin:0em !important;
		padding: .4em 1.5em;
		display: inline;
		border:none;
	}

	.wp-video { width: 100% !important }
	.wp-video video { width: 100% !important; height: 100% !important; }

// 	.wp-element-button {
// 		display: inline-block;
// 		font-size: 1.1875rem;
// 		font-weight: 600;
// 		padding: 0.8em 2.2em;
// 		background: #003087;
// 		border: none;
// 		text-decoration: none;
// 		margin-bottom: 0;
// 		color: #fff;
// 		border-radius: 50px;
// 		line-height: 1.1;
// 		cursor: pointer;
// 		transition: background 0.2s linear, color 0.2s linear;
// 		opacity: 1 !important;
// 		margin-left: 0;

// 	}
}
