.c-marquee {
	--gap: 1rem;
	display: flex;
	width: 100%;
	padding: var(--font-size--1) 0;
	padding-top: 0;
	text-decoration: none;
	overflow: hidden;
	margin-bottom: 0.75em;
	
	@media (min-width: $medium) {
		padding: var(--font-size-2) 0;
		margin-bottom: 1em;
	}


	&:hover {

		ul {
			animation-play-state: paused;
		}
	}

	ul {
		flex-shrink: 0;
		display: flex;
		min-width: 100%;
		animation: scroll 50s linear infinite;
		color: var(--theme);
		margin: 0;
		padding: .5em 0;
		padding-right: .5em;
		list-style: none;
	}

	li {
		font-size: var(--font-size-2);
		background-size: rem(42px) auto;
		margin-left: 0.8em;
		padding-left: 0.33em;
		list-style-type: "•";

		&::marker {
			text-align: center;
			color:var(--accentcolor)
		}
	}

	em {
		display: inline-block;
		text-transform: uppercase;
		color: var(--accentcolor);
		font-style: normal;
	}

	a,i {
		display: inline-block;
		// color: var(--secondary-green);
		color: var(--primary-rosa);
	}
}

@keyframes scroll {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(calc(-100%));
	}
}
