.c-contacts {

	display: flex;
  	
	flex-direction: row;

	
	li {
		list-style: none;
		padding: 1.5em 0em;
		border-bottom: 1px solid rgba(255, 255, 255, 0.10);
	}
	
	ul {
		max-width: 60em;
		width: 100%;
		margin: 0 auto;
		padding-left: 1.5em;

		margin-bottom: 4rem;
	}

	.nav {
		// position: absolute;
		// left: -0.5em;
		position: sticky;
		height: 100%;
		margin-top: 1.5em;
		margin-bottom: 1.5em;
		margin-left: 0.5em;
		top: 0;
		
		@media (max-width: $medium) {
			margin-left: -0.5em;
		}

		a,
		button {
			text-align: center;
			display: block;
			background: none;
			border: none;
			padding: 0;
			color: #fff;
			font-size: var(--font-size-0);
			text-transform: uppercase;
			color: rgba(255, 255, 255, 0.5);
			border-radius: 50%;
			width: em(30px);
			height: em(30px);
			line-height: em(30px);
			margin-top: 0.333em;

			&.active {
				background: var(--primary-white);
				color: var(--primary-dark);
			}
		}
	}

}
// .c-contacts {
// 	position: relative;

// 	@media (max-width: $small-max) {
// 		padding-left: 2em;
// 		margin-left: -0.3em;
// 		margin-right: -0.5em;
// 	}

// 	.nav {
// 		position: absolute;
// 		left: -0.5em;
// 		top: 0;
// 		height: 100%;

// 		@media (min-width: $medium) {
// 			left: em(-135px);
// 		}

// 		& > .inner {
// 			position: sticky;
// 			top: 1em;
// 		}

// 		a,
// 		button {
// 			text-align: center;
// 			display: block;
// 			background: none;
// 			border: none;
// 			padding: 0;
// 			color: #fff;
// 			font-size: em(12px);
// 			text-transform: uppercase;
// 			color: rgba(255, 255, 255, 0.5);
// 			border-radius: 50%;
// 			width: rem(30px);
// 			height: rem(30px);
// 			line-height: rem(30px);
// 			margin-top: -0.4em;

// 			&.active {
// 				color: var(--primary-dark);
// 			}
// 		}
// 	}

// 	ul {
// 		margin: 0;
// 		padding: 0;
// 		list-style: none;
// 	}

// 	li {
// 		position: relative;

// 		@media (min-width: $medium) {
// 			border-bottom: 1px solid var(--theme);
// 			border-radius: 0;
// 		}

// 		@media (max-width: $small-max) {
// 			margin-bottom: 0.5em;
// 		}
// 	}

// 	a {
// 		&:hover {
// 			text-decoration: none;
// 		}
// 	}
// }
