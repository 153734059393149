.c-ig-feed {



	label {
		display: block;
		padding: 1em;
		text-align: center;
	}
	a {
		display:block;
		border-radius: var(--radius-1);
		overflow: hidden;
		margin-bottom: var(--font-size-1);
		text-decoration: none;
		
		&:hover img {
			transform: scale(1.05);
		}

		div {
			word-wrap: break-word;
			overflow-wrap: break-word;
			word-wrap: break-word;
			max-width: 33%;
		}
	}

	img {
		transition: all 0.4s ease;
		width: 100%;
	}

}