// Common
h2,
h3,
h4,
h5,
h6 {
	margin-top: 1.5em;
	// margin-bottom: .5rem;
	// line-height: 1.2;

	& + * {
		// margin-top: 1.5rem !important;
	}
}

h2 {
	// font-size: rem(26px);
	font-size: var(--font-size-3);
}

h3 {
	// font-size: rem(20px);
	font-size: var(--font-size-2);
}

h4 {
	// font-size: rem(20px);
	font-size: var(--font-size-1);
}

h5 {
	// font-size: rem(20px);
	font-size: var(--font-size-0);
	font-weight: 400;
}

h6 {
	// font-size: rem(20px);
	font-size: var(--font-size-0);
	font-weight: bold;
}

p {
	// font-size: rem(16px);
	font-size: inherit;

	@include block-margin-small();
}

a {
	color: var(--accentcolor);
}

p,
li {
	a:link,
	a:visited,
	a:hover,
	a:active {
		text-decoration: underline;
	}
}

p.is-style-ingress {
	// font-size: rem(20px);
}

ul,
ol {
	line-height: 1.6;
	list-style-position: outside;
	@include block-margin-small();

	li {
		margin-bottom: 0.5em;
		margin-left: 1.5em;
		padding-left: 0em;

		&::marker {
			color: var(--accentcolor);
		}

	}

	ul,
	ol {
		margin: 0.5em 0;

		li {
			margin-bottom: .25em;
		}
	}
}

ul li::marker {
	font-size: 1.33rem;
	line-height: 1;
}

code {
	background: #eee;
}

figcaption {
	@include caption();
}

// Image
.wp-block-image {
	@include block-margin();

	&.alignfull,
	&.alignwide {
		.img {
			width: 100%;
		}
	}
}

// Quotes
.wp-block-quote,
.wp-block-pullquote {
	@include block-margin();

	p {
		color: var(--accentcolor);
		// font-size: rem(25px);
		margin: 1em 0;
		line-height: 1.4;
	}

	cite,
	.wp-block-quote__citation,
	.wp-block-pullquote__citation {
		font-style: normal;
		// font-size: rem(19px);
		color: var(--primary-dark);
		text-transform: none;
	}
}

.wp-block-quote {
	@include block-margin();

	border-left: 2px solid var(--accentcolor);
	padding-left: 1.5rem !important;

	p {
		font-size: var(--font-size-2);
		margin-bottom: 0.5em;
	}
}

.wp-block-pullquote {
	border-top: 2px solid var(--accentcolor);
	border-bottom: 2px solid var(--accentcolor);

	blockquote {
		margin: 0;
		padding: 0 1.5rem;

		@include container(var(--container));
	}
}

// Columns
.wp-block-columns {
	@include block-margin();

	.wp-block-column {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}

		& > :first-child {
			margin-top: 0;
		}

		& > :last-child {
			margin-bottom: 0;
		}
	}
}

// Embed
.wp-block-embed {
	@include block-margin();
	iframe {
		width: 100%;
	}
}

// Table
.wp-block-table {
	overflow-y: hidden;
	font-size: 1rem;

	@include block-margin();

	table {
		width: 100%;
		border-collapse: collapse;
	}

	thead {
		border-bottom: none;
	}

	tfoot {
		border-top: none;

		td {
			border-top: 1px solid var(--primary-dark);
		}
	}

	th {
		text-align: left;
		background: var(--accentcolor);
		color: #fff;
		font-weight: 400;
		border-bottom: 1px solid #eee;
	}

	td,
	th {
		padding: 1em;
		border: none;
		// border-bottom: 1px solid #eee;
	}
}

// Buttons
.wp-block-buttons {
	@include block-margin-small();

	.wp-block-button {
		margin-top: 0;

		&.is-style-outline {
			.wp-block-button__link {
				background: #fff;
				color: var(--accentcolor);
				border: 1px solid var(--accentcolor);
			}
		}
	}

	.wp-block-button__link {
		@include button();
	}
}

// Group
.wp-block-group {
	@include block-margin();

	.wp-block-group__inner-container {
		background: #eee;
		padding-top: rem(50px);
		padding-bottom: rem(50px);

		@include container-padding();

		& > * {
			@include container(var(--container));
		}

		.alignwide {
			max-width: var(--container-wide);
		}

		.alignfull {
			max-width: none;
		}

		& > :first-child {
			margin-top: 0;
		}

		& > :last-child {
			margin-bottom: 0;
		}
	}
}

// File
.wp-block-file {
	@include block-margin();

	a,
	.wp-block-file__textlink {
		color: var(--primary-dark);

		&:first-of-type {
			margin-right: 1.5em;
			color: var(--accentcolor);
		}
	}

	.wp-block-file__button {
		@include button();
		// color: var(--primary-dark);
		background: var(--accentcolor);

		opacity: 1 !important;
		margin-left: 0;

		@media (max-width: $small-max) {
			margin-top: 1em;
		}
	}
}

// Media text
.wp-block-media-text {
	@include block-margin();

	@media (max-width: $medium-max) {
		display: block;
	}

	.wp-block-media-text__content {
		& > :first-child {
			margin-top: 0;
		}

		& > :last-child {
			margin-bottom: 0;
		}
	}

	.wp-block-media-text__media {
		margin: 0 0 2em 0;

		@media (min-width: $large) {
			margin: 0;
		}
	}
}

// Gallery
.wp-block-gallery {
	@include block-margin();

	ul {
		width: 100%;
		margin: 0;

		li {
			padding-left: 0;
			margin-left: 0;
		}

		& + figcaption {
			width: 100%;
		}
	}
}

// Cover
.wp-block-cover {
	width: auto;

	@include block-margin();

	p {
		line-height: 1.4;
		padding: 0 1.5rem;

		@include container(var(--container));
	}
}

// Separator
.wp-block-separator {
	border: none;
	background: var(--accentcolor);
	opacity: 0.25;
	height: 1px;

	@include block-margin();
}

// Social links
.wp-block-social-links {
	@include block-margin();

	li {
		padding-left: 0;
		margin-left: 0;
	}

	a {
		color: #fff;
	}

	&:not(.is-style-logos-only) {
		.wp-social-link {
			background: var(--accentcolor);
		}
	}
}

// Code
.wp-block-code {
	padding: 1rem;
	background: #eee;
	border-radius: 0;
	border: none;

	@include block-margin();
}

// Numbers

.wp-block-dramaqueen-numbers {
	@include block-margin();

	.row {
		display: flex;
		flex-direction: row;
  		justify-content: space-evenly;
		flex-wrap: wrap;
		// column-gap: 3.5em;
		row-gap: 4em;
	}
	.wp-block-dramaqueen-numbers-item {
		flex: 1 1 50%;
		// min-width: 13em;
		text-align: left;
		// background: var(--gray);
		border-radius: 10px;
		position: relative;
		// overflow: hidden;
		display: flex;
		flex-direction: row;
  		align-items: flex-end;
	}
	p {
		margin-bottom: 0;
		display: inline-block;
	}
	.number {
		font-size: 6.25rem;
		font-weight: 700;
		margin: 0;
		line-height: 0.8;
		color: #232025;
		color: var(--dark);
		text-shadow: 1px 1px 0 #fe7d62, -1px 1px 0 #fe7d62, 1px -1px 0 #fe7d62,
			-1px -1px 0 #fe7d62, 0px 1px 0 #fe7d62, 0px -1px 0 #fe7d62,
			-1px 0px 0 #fe7d62, 1px 0px 0 #fe7d62, 1.5px 1.5px 0 #fe7d62,
			-1.5px 1.5px 0 #fe7d62, 1.5px -1.5px 0 #fe7d62, -1.5px -1.5px 0 #fe7d62,
			0px 1.5px 0 #fe7d62, 0px -1.5px 0 #fe7d62, -1.5px 0px 0 #fe7d62,
			1.5px 0px 0 #fe7d62, 1px 1.5px 0 #fe7d62, -1px 1.5px 0 #fe7d62,
			1px -1.5px 0 #fe7d62, -1px -1.5px 0 #fe7d62, 1.5px 1px 0 #fe7d62,
			-1.5px 1px 0 #fe7d62, 1.5px -1px 0 #fe7d62, -1.5px -1px 0 #fe7d62;
		text-shadow: 1px 1px 0 var(--theme), -1px 1px 0 var(--theme),
			1px -1px 0 var(--theme), -1px -1px 0 var(--theme), 0px 1px 0 var(--theme),
			0px -1px 0 var(--theme), -1px 0px 0 var(--theme), 1px 0px 0 var(--theme),
			1.5px 1.5px 0 var(--theme), -1.5px 1.5px 0 var(--theme),
			1.5px -1.5px 0 var(--theme), -1.5px -1.5px 0 var(--theme),
			0px 1.5px 0 var(--theme), 0px -1.5px 0 var(--theme),
			-1.5px 0px 0 var(--theme), 1.5px 0px 0 var(--theme),
			1px 1.5px 0 var(--theme), -1px 1.5px 0 var(--theme),
			1px -1.5px 0 var(--theme), -1px -1.5px 0 var(--theme),
			1.5px 1px 0 var(--theme), -1.5px 1px 0 var(--theme),
			1.5px -1px 0 var(--theme), -1.5px -1px 0 var(--theme);
	}

	.text {
		font-size: 1.875rem;
		margin-left: 0.5rem;
	}
	.number-mask {
		top: -100%;
		left: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		background: #232025;
		background: var(--dark);
	}
}
