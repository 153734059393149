$c-verylightfade: rgba(127, 127, 127, 0.05);
// $c-body-bg: darken(#011093, 2%);
$c-lightgray: #e7e7e7;
$c-gray: #adacac;
$c-dark: #222;
$c-accentcolor: #009ee2;
$c-strongaccentcolor: #c4007a;
$c-pink: #e4b3d0;
$c-text: #fff;
$c-strong: #252b70;
$secondarycolor: $c-lightgray;

$button-shadow: 0.1em 0.3em 0.8em -0.2em rgba(0, 0, 0, 0.1);

// 'Source Sans Pro', sans-serif;
$f-basic: 'Source Sans Pro', sans-serif;
$f-bold: 'Source Sans Pro', sans-serif;

// gradients:
$g-bluepink: linear-gradient(45deg, #009ee233, #e4b3d036);

$form-width: 1024px;
$content-width: 1200px;
$list-width: 1400px;

// Screen size breakpoints (for min-width queries)
$small: 480px;
$medium: 641px;
$large: 880px;
$xlarge: 1200px;
$xxlarge: 1360px;

// Screen size breakpoints (for max-width queries).
$small-max: $medium - 1px;
$medium-max: $large - 1px;
$large-max: $xlarge - 1px;

@import 'mixins';
div.admin {
	@import 'base';
	@import 'reacttable';
	@import 'toastify';
	@import 'form';
	@import 'adminstyles';
}
