// // Header

.c-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	// gap: 2.5em;

	padding: var(--container-padding) 1em;
	@media (min-width: $large) {
		padding: var(--container-padding);
		// padding: 2em 4.75em;
	}

	.logo svg{
		max-width: 3em;
	}

	.logo, .cog-menu {
		flex: 1 1 3%;
		display: flex;
		min-width: 4em;
		justify-content: center;
		// background-color: teal;
	}

	.cog-menu {
		flex-direction: row;
  		justify-content: center;
  		align-items: center;
		transition: all 0.3s ease;
		min-width: 3em;

		@media (min-width: $large) {
			min-width: 4em;
		}
	}

	.search-input-wrapper {
		flex: 1 1 100%;
		padding: 0em var(--font-size-0);		
		// margin: 0em var(--font-size-1);
	}

	.search-input {
		width: 100%;
		border-radius: var(--radius-3);
		padding: 1em;
		// margin: 0em var(--font-size-1);
		border:none;
		color: #fff;		
		background: url("/images/search.svg") 1em 50% no-repeat;
		background-color: rgba(255,255,255,0.1);	
		padding-left: 3em;	
		filter: drop-shadow(0px 1.5em 1.5em rgba(0, 0, 0, 0.2));

		&:hover {
			filter: drop-shadow(0px 1.5em 1.5em rgba(0, 0, 0, 0.4));
			background-color: rgba(255,255,255,0.12);
		}
		
		&:focus {
			background-color: rgba(255,255,255,0.2);
			filter: drop-shadow(0px 1.5em 1.5em rgba(0, 0, 0, 0.7));
			outline:2px solid white;
		}
		&::placeholder {
			// padding-left: 2em;
			color:#fff;
		}
	}

	.logo:hover {
		transform: rotate(-11deg);		
	}
	.cog-menu a:hover {
		transform: rotate(11deg);
	}

	.logo, .cog-menu a {
		transition: all 0.15s ease-in-out;

		&:active {
			transform: scale(0.9);
		}
	}
	
}

/*
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.6667 8.33333C16.6667 12.9357 12.9357 16.6667 8.33333 16.6667C3.73096 16.6667 0 12.9357 0 8.33333C0 3.73096 3.73096 0 8.33333 0C12.9357 0 16.6667 3.73096 16.6667 8.33333ZM14.1667 8.33333C14.1667 11.555 11.555 14.1667 8.33333 14.1667C5.11167 14.1667 2.5 11.555 2.5 8.33333C2.5 5.11167 5.11167 2.5 8.33333 2.5C11.555 2.5 14.1667 5.11167 14.1667 8.33333Z" fill="white"/>
<path d="M17.5 20C18.8807 20 20 18.8807 20 17.5C20 16.1193 18.8807 15 17.5 15C16.1193 15 15 16.1193 15 17.5C15 18.8807 16.1193 20 17.5 20Z" fill="white"/>
</g>
</svg>

*/

// .c-header {
// 	height: var(--header-height-small);
// 	transition: height .2s linear, transform .2s ease-out, background .2s ease-out, box-shadow .2s ease-out;
// 	display: flex;
// 	justify-content: space-between;
// 	align-items: center;
// 	margin-bottom: .5em;
// 	padding-top: .5em;

// 	@media (min-width: $desktop-nav) {
// 		padding-left: 3em;
// 		padding-right: 3em;
// 		height: var(--header-height);
// 	}

// 	.back {
// 		background: none;
// 		border: none;

// 		@media (min-width: $medium) {
// 			visibility: hidden;
// 		}
// 	}

// 	.search {
// 		background: none;
// 		border: none;
// 		color: #fff;
// 	}

// 	// Logo
// 	.logo {
// 		// transition: top .2s ease-out;
// 		color: #fff;

// 		svg {
// 			width: em(98px);
// 			// transition: width .2s ease-out, height .2s ease-out;
// 			// width: em(80px);
// 			// height: em(23px);

// 			// @media (min-width: $desktop-nav) {
// 			// 	width: em(120px);
// 			// 	height: em(34px);
// 			// }
// 		}
// 	}

// 	// Logged out
// 	&.logged-out {
// 		justify-content: center;

// 		.back {
// 			display: none;
// 		}

// 		.search {
// 			display: none;
// 		}
// 	}

// 	// Skip link
// 	.skip-link {
// 		display: block;
// 		position: absolute;
// 		left: 0;
// 		top: 0;
// 		width: 100%;
// 		text-align: center;

// 		a {
// 			position: absolute;
// 			width: 1px;
// 			height: 1px;
// 			margin: -1px;
// 			overflow: hidden;
// 			clip: rect(0,0,0,0);
// 			border: none;
// 			background: #fff;
// 			padding: 1em 3em;
// 			display: inline-block;

// 			&:focus {
// 				position: static;
// 				width: auto;
// 				height: auto;
// 				margin: 0;
// 				overflow: visible;
// 				clip: auto;
// 			}
// 		}
// 	}
// }
