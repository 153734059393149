// Media queries

@mixin min($value) {
	@media screen and (min-width: $value) {
		@content;
	}
}

@mixin max($value) {
	@media screen and (max-width: $value) {
		@content;
	}
}
