.c-requestpermission {
	
}
.c-switch {
	cursor: pointer;
	display: block;
	font-size: var(--font-size-0);

	input {
		position: absolute;
		opacity: 0;

		&:checked + span {
			background: var(--secondary-green);

			&::before {
				background: #fff;
				border-color: #fff;
				left: calc(100% - 1em - 3px);
			}
		}

		&:disabled + span {
			background: #949494;

			&::before {
				background: #fff;
			}
		}

		&:focus.focus-visible + span {
			outline: 2px solid var(--secondary-green);
			outline: 2px solid -webkit-focus-ring-color;
		}
	}

	& > span:not(.icon) {
		position: relative;
		display: inline-block;
		border-radius: 30px;
		width: em(36px);
		height: em(24px);
		padding: 2px;
		vertical-align: middle;
		margin-right: 2em;
		transition: background 0.2s ease-out;
		background: #ccc;

		&::before {
			content: '';
			width: em(16px);
			height: em(16px);
			border-radius: 50%;
			position: absolute;
			left: em(3px);
			top: em(4px);
			transition: left 0.2s ease-out, background 0.2s ease-out,
				border 0.2s ease-out;
			background: #fff;
		}
	}
}
