// FRONTPAGE
.c-frontpage {

	.widelayout {
		display: grid;
		align-items: start;
		gap: 1.75em;
		justify-content: center;
		grid-template-columns: minmax(0, 1fr);

		@media (min-width: $medium) {
			gap: 2em;
		}
		@media (min-width: $xlarge) {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.c-snazzymenu, .c-iconmenu, .c-ig-feed {
		@media (min-width: $xlarge) {
			position: sticky;
			top:1em;

		}		
	}

}