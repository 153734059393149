// Container

@mixin container($width: var(--default-container), $padding: false) {
	max-width: $width;
	margin-left: auto;
	margin-right: auto;

	@if($padding) {
		@include container-padding();
	}
}

@mixin container-padding() {
	box-sizing: content-box;
	padding-left: var(--container-padding);
	padding-right: var(--container-padding);
}

// Visibility

@mixin hide($value) {
	@media (min-width: $value) {
		display: none !important;
	}
}

@mixin show($value, $display: block) {
	@media screen {
		display: none;
	}

	@media (min-width: $value) {
		display: $display !important;
	}
}

// Blocks

@mixin block-margin() {
	margin-top: rem(50px);
	margin-bottom: rem(50px);
}

@mixin block-margin-small() {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
}

@mixin button() {
	// display: inline-block;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	gap: 8px;

	// padding: 10px 32px;

	font-size: var(--font-size--1);
	font-weight: 700;
	
	padding: .8em 3em;
	margin-bottom: 0;


	cursor: pointer;
	transition: background .2s linear, color .2s linear;
	text-transform: uppercase;
	text-decoration: none;
	line-height: 1.1;
	border-radius: var(--radius-4);
	
	--button-color: var(--primary-white);
	--button-bg: var(--primary-dark);

	background: var(--button-bg);
	border: 2px solid var(--button-bg);
	color: var(--button-color);
	box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);

	&.small {
		font-size: var(--font-size--2);
		padding: .8em 2em;
	}

	&.tiny {
		font-size: var(--font-size--2);
		padding: .5em 1.5em;
	}


	&:hover {
		background:none;
		color:var(--button-bg);
		text-decoration: none;
		box-shadow: 0 6px 8px rgba(0, 0, 0, 0.25);
	}

	&.white, &.light {
		--button-color: var(--primary-dark);
		--button-bg: var(--primary-white);
	}

	&.colorful {
		--button-color: var(--primary-dark);
		--button-bg: var(--primary-white);

		background: linear-gradient(217deg, rgba(255,0,0,.33), rgba(255,0,0,0) 70.71%),
			linear-gradient(-53deg, rgba(0,255,0,.33), rgba(0,255,0,0) 70.71%),
			linear-gradient(336deg, rgba(0,0,255,.33), rgba(0,0,255,0) 70.71%), linear-gradient(0deg,#fff,#fff 100%);

		&:hover {
			color: var(--primary-dark);
		}
	}

	&.advent {
		// --button-color: var(--primary-rosa);
		--button-bg: var(--advent-red);
		border-color: var(--primary-rosa);

		// background: linear-gradient(90deg, var(--advent-red), var(--primary-rosa)); /* Red to gold gradient */
		// color: #fff;
		// font-weight: bold;
		// border: 2px solid #fff;
		// border-radius: 12px;
		// padding: 10px 20px;
		// text-transform: uppercase;
		// box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
		// cursor: pointer;
		position: relative;
		// padding-left: 3em;

		// &::before {
		// 	content: "🎄";
		// 	position: absolute;
		// 	left: 10px;
		// 	font-size: 1.2rem;
		// }

		&:hover {
			--button-bg: var(--primary-white);
			// background: linear-gradient(90deg, var(--advent-red), var(--secondary-green));
			box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
		}

	}


	&.orange {
		--button-color: var(--primary-white);
		--button-bg: var(--primary-orange);
	}

	&.rosa {
		--button-color: var(--secondary-blue);
		--button-bg: var(--primary-rosa);
	}

	&.blue {
		--button-color: var(--primary-rosa);
		--button-bg: var(--secondary-blue);
	}

	&.green {
		--button-color: var(--secondary-green);
		--button-bg: var(--primary-white);
	}

	&.ghost {
		--button-color: var(--primary-dark);
		--button-bg: transparent;
		border-color: transparent;

		&:hover {
			background: var(--secondary-light-gray);
			color: var(--primary-dark);
		}
	}

}

@mixin caption {
	margin-top: 1.5rem;
	line-height: 1.4;
	text-align: center;
	font-size: 1rem;
	color: var(--primary-dark);
	opacity: .7;
}

// Misc

@mixin variable($value) {
	font-family: "#{$value}";
}

@mixin video-embed($ratio: 56.25%) {
	position: relative;
	padding-bottom: $ratio;
	height: 0;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

@mixin object-fit() {
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
	width: auto;
	height: auto;
	max-width: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);

	@supports (object-fit: cover) {
		width: 100%;
		height: 100%;
	}
}
